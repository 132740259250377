import { StringDecoder } from 'string_decoder'
import React, { useState } from 'react'
import { useRouter } from 'next/navigation'
import { FieldErrors, useForm } from 'react-hook-form'
import { useAppContext, useAppContextSelector } from 'src/app-components/AppContext'
import { useNotesService } from 'src/app-components/Notes/useNotesService'
import { formatCallingCodeOption, formatContactOption } from 'src/lib/utils'
import { routes } from 'src/lib/utils/routes'

import { ButtonV2, SheetFooter, SheetHeader, SheetTitle, useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { ENDPOINTS } from '@app/constants'
import { useLeadService } from '@app/hooks'
import { CountryType, ENTITY, LeadResponse, NoteResponse, Option, SelectOption } from '@app/types'

import { CreateWizardBaseContextType } from '..'
import { NewLeadForm } from './NewLeadForm'
import { Form } from '@app/components/Form'

export type NewLeadFormData = {
  firstName: string
  lastName: string
  primaryRep: Option
  companyName?: string
  title?: string
  callingCode?: SelectOption<CountryType>
  phone?: string
  extension?: string
  primaryEmail?: string
  notes?: string
  // Fields that may be provided from other Create contexts rather than from from the Form fields
  campaignId?: string
  source?: string
  division?: Option
}

export type LeadWizardContext = CreateWizardBaseContextType<LeadResponse, NewLeadFormData>

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultValues?: Partial<NewLeadFormData>
}

export function CreateLead({ setOpen, defaultValues }: Props) {
  const countries = useAppContextSelector((state) => state.countries)
  const callingCodeOptionsMap = new Map(countries.map((country) => [country.alpha3code, formatCallingCodeOption(country)]))
  const notification = useNotification()
  const router = useRouter()
  const service = useLeadService()
  const { request } = useRequestCallback()
  const { userContact, leadData, setLeadData } = useAppContext()

  const createForm = useForm<NewLeadFormData>({
    defaultValues: {
      firstName: defaultValues?.firstName || '',
      lastName: defaultValues?.lastName || '',
      primaryRep: defaultValues?.primaryRep ?? userContact ? formatContactOption(userContact) : null,
      companyName: defaultValues?.companyName || '',
      title: defaultValues?.title || '',
      callingCode: callingCodeOptionsMap.get('USA'),
      phone: defaultValues?.phone || '',
      extension: defaultValues?.extension || '',
      primaryEmail: defaultValues?.primaryEmail || '',
      notes: defaultValues?.notes || '',
      campaignId: defaultValues?.campaignId || null,
      source: defaultValues?.source ?? null,
    },
  })

  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)

  const canSubmit = status !== 'loading'

  const closeSheet = () => setOpen(false)
  function handleSuccessfulCreate(response: LeadResponse) {
    // setStatus('success')
    // createForm.reset()
    setLeadData(null)
    closeSheet()
    router.push(routes.lead.general(response.id))
  }

  const entityType = 'Lead'
  function handleFailedCreate(error: any) {
    setError(error as Error)
    setStatus('error')
    notification.api.notify({
      message: `Error Creating ${entityType} - ${error.message}`,
      type: 'warning',
    })
  }

  async function onSubmit(data: NewLeadFormData) {
    setStatus('loading')
    try {
      const { notes, ...newLead } = data
      const leadRequest = { ...leadData, ...newLead }
      const createdLead = await service.create(leadRequest)
      if (notes || notes?.trim()) {
        const createNote = await request<NoteResponse>({
          path: ENDPOINTS.LEADS_NOTES(createdLead.id),
          method: 'POST',
          body: JSON.stringify({ id: createdLead.id, content: notes }),
        })
      }
      handleSuccessfulCreate(createdLead)
    } catch (error: any) {
      handleFailedCreate(error)
    }
  }

  function onSubmitError(errors: FieldErrors) {
    notification.api.notify({
      message: 'Some fields are missing or incorrect',
      type: 'warning',
    })
    console.error(errors)
  }

  return (
    <Form form={createForm} isEditing onSubmit={createForm.handleSubmit(onSubmit, onSubmitError)}>
      <SheetHeader className="pb-4">
        <SheetTitle className="text-4xl">Create {entityType}</SheetTitle>
      </SheetHeader>
      <NewLeadForm form={createForm} />
      <SheetFooter className="flex justify-end gap-sm py-12">
        <ButtonV2 size="lg" type="button" variant="secondary" onClick={() => createForm.reset()}>
          Reset
        </ButtonV2>
        <ButtonV2 size="lg" type="submit" variant="primary" className="font-bold" loading={status === 'loading'} disabled={!canSubmit}>
          Save as New {entityType}
        </ButtonV2>
      </SheetFooter>
    </Form>
  )
}
