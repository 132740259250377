import React from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'
import { useAppContextSelector } from 'src/app-components/AppContext'
import { formatCallingCodeOption, formatCountryOption, formatSingleENUM, getPhoneRequest } from 'src/lib/utils'

import { useNotification, Wizard } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { useAccountService } from '@app/hooks'
import { ACCOUNT_ADDRESS_LABEL, AccountResponse, AddressRequest, CountryType, NewAccountFormData, SelectOption } from '@app/types'

import { CreateWizardBaseContextType } from '..'
import { MergeStep } from '../MergeStep'
import { getWizardConfig } from '../utils'
import { AccountDuplicatesStep } from './AccountDuplicatesStep'
import { AccountMergeTable } from './AccountMergeTable'
import { CreateStep } from './CreateStep'
import { DuplicateAccountCard } from './DuplicateAccountCard'
import { transformAccountMergeData } from './transformers'
import { useAccountMatches } from './useAccountMatches'

export type AccountMergeFormData = {
  isNewPhoneSelected: boolean
  isNewAddressSelected: boolean
  isNewIndustrySelected: boolean
}

export type AccountWizardContext = CreateWizardBaseContextType<AccountResponse, NewAccountFormData>

const { Step } = Wizard

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function CreateAccountWizard({ setOpen }: Props) {
  const router = useRouter()
  const pathname = usePathname()
  const notification = useNotification()
  const AccountsService = useAccountService({ update: undefined })
  const DuplicatesService = useAccountMatches()
  const countries = useAppContextSelector((state) => state.countries)
  const { selectedMatch, setSelectedMatch } = DuplicatesService
  // should the form submission skip checking for duplicates
  const [shouldProceed, setShouldProceed] = React.useState(false)

  // ? New account created in Create Step
  const [newAccountStatus, setNewAccountStatus] = React.useState<Status>()

  // ? The full account payload needed in Merge Step
  const [account, setAccount] = React.useState<AccountResponse>()
  const [accountStatus, setAccountStatus] = React.useState<Status>('idle')
  const [accountError, setAccountError] = React.useState<Error | null>(null)

  // ? The state of the account after PUT request in Merge Step
  const [updatedAccount, setUpdatedAccount] = React.useState<AccountResponse>()
  const [updatedAccountStatus, setUpdatedAccountStatus] = React.useState<Status>('idle')
  const [updatedAccountError, setUpdatedAccountError] = React.useState<Error | null>(null)

  const countryOptionsMap = new Map(countries.map((country) => [country.name, formatCountryOption(country)]))

  const callingCodeOptionsMap = new Map(countries.map((country) => [country.name, formatCallingCodeOption(country)]))

  const createForm = useForm<NewAccountFormData>({
    defaultValues: {
      name: '',
      callingCode: callingCodeOptionsMap.get('United States of America'),
      phone: '',
      extension: '',
      industries: null,
      address: {
        city: '',
        country: countryOptionsMap.get('United States of America'),
        postalCode: '',
        line1: '',
        line2: '',
        label: formatSingleENUM('COMPANY_ADDRESS'),
        state: null,
        isPrimary: true,
      },
    },
  })

  const mergeForm = useForm<AccountMergeFormData>()

  const config = getWizardConfig('Account')

  const contextValue: AccountWizardContext = {
    ...config,
    duplicateMatches: DuplicatesService,
    shouldProceed,
    setShouldProceed,
    selectedMatch,
    setSelectedMatch,
    createForm,
  }

  const getFullEntityPayload = async () => {
    setAccountStatus('loading')
    try {
      const res = await AccountsService.getById(selectedMatch.id)
      setAccount(res)
      setAccountStatus('success')
    } catch (error: any) {
      setAccountError(error)
      setAccountStatus('error')
    }
  }

  const selectAccount = (account: AccountResponse) => {
    setAccount(undefined)
    setSelectedMatch(account)
    setAccountStatus('idle')
  }

  const renderAccountMatch = (account: AccountResponse) => (
    <DuplicateAccountCard account={account} selectedMatchId={selectedMatch?.id} onClickCard={selectAccount} />
  )

  const closeSheet = () => setOpen(false)

  function handleSuccessfulCreate(response: AccountResponse) {
    closeSheet()
    router.push(config.getSuccessPath(response.id))
  }

  function handleFailedCreate(error: any) {
    setNewAccountStatus('error')
    notification.api.notify({
      message: `Error Creating ${config.entityType} - ${error.message}`,
      type: 'warning',
    })
  }

  async function createNewAccount() {
    const data = createForm.getValues()

    const address: AddressRequest = {
      city: data.address.city,
      state: data.address.state.value,
      country: data.address.country.value.name,
      line1: data.address.line1,
      line2: data.address.line2,
      postalCode: data.address.postalCode,
      isPrimary: data.address.isPrimary,
      label: data.address.label.value,
    }

    const newPhoneData = {
      label: 'Phone',
      number: data.phone,
      callingCode: data.callingCode,
      isPrimary: true,
      extension: data.extension,
    }

    const requestBody = {
      name: data.name,
      industries: data.industries?.map((industry) => ({ title: industry.value })),
      phones: [getPhoneRequest(newPhoneData)],
      addresses: [address],
    }
    const response = await AccountsService.create(requestBody as any) // ! Conflict with account request/ new account form data types, wait for types fix PR
    return response
  }

  async function saveAsNew() {
    setNewAccountStatus('loading')
    try {
      const res = await createNewAccount()
      handleSuccessfulCreate(res)
    } catch (e) {
      handleFailedCreate(e)
    }
  }

  async function onSubmitMerge(mergeFormData: AccountMergeFormData) {
    const createFormData = createForm.getValues()
    const requestBody = transformAccountMergeData({ createFormData, mergeFormData, account })

    setUpdatedAccountStatus('loading')

    try {
      const response = await AccountsService.updateById(requestBody, selectedMatch.id)
      closeSheet()
      router.push(config.getSuccessPath(response.id))
    } catch (error: any) {
      setUpdatedAccountStatus('error')
      notification.api.notify({
        type: 'warning',
        message: `Failed to merge contact.\n\n${error.message}`,
      })
    }
  }

  const entityState = { data: account, status: accountStatus, error: accountError }

  const updatedEntityState = { data: updatedAccount, status: updatedAccountStatus, error: updatedAccountError }

  return (
    <Wizard contextValue={contextValue}>
      <Step hideControls header={`Create ${config.entityType}`}>
        <CreateStep createNewAccount={saveAsNew} setNewAccountStatus={setNewAccountStatus} newAccountStatus={newAccountStatus} />
      </Step>
      <Step hideControls header={`Select ${config.entityType} Duplicate`}>
        <AccountDuplicatesStep
          newAccountStatus={newAccountStatus}
          entityType={config.entityType}
          matches={DuplicatesService.matches}
          renderAccountMatch={renderAccountMatch}
          createNewAccount={saveAsNew}
        />
      </Step>
      <Step hideControls header={`Merge ${config.entityType}`}>
        <MergeStep
          successPath={config.getSuccessPath(updatedAccount?.id)}
          getFullEntityPayload={getFullEntityPayload}
          entityType={config.entityType}
          entityState={entityState}
          updatedEntityState={updatedEntityState}
          onSubmit={mergeForm.handleSubmit(onSubmitMerge)}
          mergeForm={mergeForm}
          selectedMatch={selectedMatch}
        >
          <AccountMergeTable account={account} createFormData={createForm.watch()} />
        </MergeStep>
      </Step>
    </Wizard>
  )
}
