'use client'

// REVIEW >> could this be a RSC?
import React from 'react'

import { SheetTrigger } from '@fsg/gui-bits'
import { Plus } from '@fsg/icons'
import { cn } from '@fsg/utils';
import styles from '@app/styles/Navbar.module.scss';

export function SheetProxyTrigger({ buttonActive }: { buttonActive: boolean; }) {
  return (
    <SheetTrigger className={cn('flex w-max items-center', { 'bg-gray-lightest': !buttonActive }, styles.createtrigger)}>
      <Plus width={12} height={12} />
    </SheetTrigger>
  )
}
