'use client'

import { PropsWithChildren, useEffect } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import { LoadingSpinner } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { MergeStepDisplay } from './MergeStepDisplay'

type RequestState<ResponseType> = {
  data: ResponseType
  status: Status
  error: Error | null
}

type Props<EntityResponseType, MergeFormData> = {
  successPath: string
  entityType: string
  entityState: RequestState<EntityResponseType>
  updatedEntityState: RequestState<EntityResponseType>
  getFullEntityPayload: () => Promise<void>
  onSubmit: (event: React.BaseSyntheticEvent<object, any, any>) => void
  mergeForm: UseFormReturn<MergeFormData>
  selectedMatch: EntityResponseType
}

export function MergeStep<E, M>(props: PropsWithChildren<Props<E, M>>) {
  const { successPath, entityType, onSubmit, getFullEntityPayload, entityState, updatedEntityState, mergeForm, selectedMatch, children } = props

  const { data: entityData, status: entityStatus, error: entityError } = entityState
  const { data: updatedEntityData, status: updatedEntityStatus, error: updatedEntityError } = updatedEntityState

  useEffect(() => {
    // ! Infinite loop for some reason without this check
    // ! Probably due to the service hook not being stable
    if (entityData) return
    getFullEntityPayload()
  }, [getFullEntityPayload, entityData, selectedMatch])

  if (entityStatus === 'idle') return <p>Idle state</p>
  if (entityStatus === 'loading') return <LoadingSpinner />
  if (entityStatus === 'error') return <p>Error getting data</p>

  return (
    <MergeStepDisplay
      entityType={entityType}
      status={updatedEntityStatus}
      loading={updatedEntityStatus === 'loading'}
      disabled={updatedEntityStatus === 'loading' || updatedEntityData !== undefined}
      onSubmit={onSubmit}
      successPath={successPath}
    >
      <FormProvider {...mergeForm}>{children}</FormProvider>
    </MergeStepDisplay>
  )
}
