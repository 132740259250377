'use client'

import React from 'react'
import { createPortal } from 'react-dom'

import { ButtonV2, Sheet, SheetContent, SheetTrigger } from '@fsg/gui-bits'

import { AccountResponse } from '@app/types'
import { preventSheetClose } from '@app/utils'

import { SheetProxyTrigger } from '.'
import { useAppContext } from '../AppContext'
import { CreateProps } from './constants'
import { CreateSite } from './Site'

interface CreateSiteSheetProps extends CreateProps {
  account?: AccountResponse
  renderOnly?: boolean
}

export function CreateSiteSheet({ navMode, buttonActive, account, renderOnly }: CreateSiteSheetProps) {
  const { showCreateSite, setShowCreateSite, onCreateFunctions } = useAppContext()
  const [show, setShow] = React.useState<boolean>(false)
  const dialogRef = React.useRef<HTMLDialogElement | null>(null)
  const open = navMode ? show : showCreateSite
  const setOpen = navMode ? setShow : setShowCreateSite

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {renderOnly ? null : navMode ? (
        <SheetProxyTrigger buttonActive={buttonActive} />
      ) : (
        <ButtonV2 asChild type="button" className="font-bold" variant="primary">
          <SheetTrigger>Create Site</SheetTrigger>
        </ButtonV2>
      )}
      <SheetContent
        side="right"
        className="w-[600px] overflow-y-scroll"
        onInteractOutside={(event) => {
          preventSheetClose(event)
        }}
      >
        <CreateSite setOpen={setOpen} account={account} />
      </SheetContent>
    </Sheet>
  )
}
