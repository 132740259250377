'use client'

import React from 'react'
import { preventSheetClose } from 'src/lib/utils'

import { ButtonV2, Sheet, SheetContent, SheetTrigger } from '@fsg/gui-bits'

import { SheetProxyTrigger } from '.'
import { useAppContext } from '../AppContext'
import { CreateProps } from './constants'
import { CreateLead, NewLeadFormData } from './Lead'

// TODO >> Sheet randomly gets blue active focus border (on save click, etc)
// TODO >> Disable fetch until mounted
// TODO >> Clear fields on success
// TODO >> Tab order to save button
// TODO >> Create Sheet Context
// * Create Context --------------
// * started, but decided to shelf it as a nice to have...
// type SheetContext = {
//   register: UseFormRegister<FieldValues>
//   errors: FieldErrors<FieldValues>
// }
// const SheetContext = React.createContext<SheetContext>(undefined)
// function SheetContextProvider({ children }: {children: React.ReactNode}) {
//   const value = {register, errors}
//   return <SheetContext.Provider value={value}>{children}</SheetContext.Provider>
// }
// * ---------/ Create Context

// TODO >> Create a CreateSheet wrapper to handle onSubmit, router, open, etc

interface Props extends CreateProps {
  defaultValues?: Partial<NewLeadFormData>
}

export function CreateLeadSheet({ navMode, buttonActive, defaultValues }: Props) {
  const { showCreateLead, setShowCreateLead, leadData, setLeadData } = useAppContext()

  const [show, setShow] = React.useState(false)
  const open = navMode ? show : showCreateLead
  const setOpen = navMode ? setShow : setShowCreateLead

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {navMode ? (
        <SheetProxyTrigger buttonActive={buttonActive} />
      ) : (
        <ButtonV2 asChild className="font-bold" size='lg' variant="primary">
          <SheetTrigger>Create Lead</SheetTrigger>
        </ButtonV2>
      )}
      <SheetContent
        side="right"
        className="w-[600px]"
        onInteractOutside={(event: any) => {
          preventSheetClose(event)
        }}
      >
        <CreateLead setOpen={setOpen} defaultValues={leadData !== null ? leadData : defaultValues} />
      </SheetContent>
    </Sheet>
  )
}
