import {
  AddressRequest,
  AddressResponse,
  ContactRoleResponse,
  EmailResponse,
  Industries,
  PhoneFormData,
  PhoneRequest,
  PhoneResponse,
} from '@app/types'
import { setDefaultPrimaryEmail, setDefaultPrimaryPhone } from '@app/utils'

// Note: These functions do not include logic to filter out duplicates because it should be handled in the form UI e.g., by disabling an input if the value is a duplicate. If an input is disabled, its value will not be stored in the form values.

export function buildNewPhonesCollection(phones: string[], entityPhones: PhoneResponse[]) {
  if (phones.length === 0) return entityPhones

  // if selected phone number exists in the form data `phones` list already
  const isExistingPhone = (phone: string) => entityPhones?.map((p) => p.number).includes(phone)
  // find and return the original object
  const getExistingPhoneObject = (phone: string) => entityPhones?.find((p) => p.number === phone)
  // otherwise just build and return a phone request object from the new phone number
  const buildPhoneRequest = (phoneNumber: string) => ({ number: phoneNumber, label: 'Phone', isPrimary: false })

  const phoneMapper = (phone: string) => {
    return isExistingPhone(phone) ? getExistingPhoneObject(phone) : buildPhoneRequest(phone)
  }

  const newCollection = Array.isArray(phones) ? phones.map(phoneMapper) : [phoneMapper(phones)]

  const final = setDefaultPrimaryPhone(newCollection)

  return final
}

export function buildNewEmailsCollection(emails: string[], entityEmails: EmailResponse[]) {
  if (emails.length === 0) return entityEmails

  const isExistingEmail = (address: string) => entityEmails?.map((e) => e.address).includes(address)
  const getExistingEmailObject = (address: string) => entityEmails?.find((e) => e.address === address)
  const buildEmailRequest = (email: string) => ({ address: email, label: 'Email', isPrimary: false })

  const emailMapper = (email: string) => {
    return isExistingEmail(email) ? getExistingEmailObject(email) : buildEmailRequest(email)
  }

  const newCollection = Array.isArray(emails) ? emails.map(emailMapper) : [emailMapper(emails)]

  const final = setDefaultPrimaryEmail(newCollection)

  return final
}

export function buildNewAddressesCollection(createFormAddress: AddressRequest, addressIds: string[], entityAddresses: AddressRequest[]) {
  const addressMapper = (addressId: string) => {
    const getExistingAddressObject = (addressId: string) => entityAddresses?.find((a) => a.id === addressId)
    return addressId === 'createFormAddress' ? createFormAddress : getExistingAddressObject(addressId)
  }

  return Array.isArray(addressIds) ? addressIds.map(addressMapper) : [addressMapper(addressIds)]
}

// ? Helper functions to check if any of the newly entered data is the already in existing data

export function checkIfPhoneIsDuplicate(newPhone: Omit<PhoneFormData, 'id' | 'label'>, phones: PhoneResponse[] | PhoneRequest[]) {
  if (!Array.isArray(phones)) return false
  const { callingCode, number, extension } = newPhone
  const matcher = (phone: PhoneResponse | PhoneRequest) => {
    // Backend will store phone.number in the format {callingCode}{number}, but some may not have the + sign included.
    return `${callingCode.value.telephoneCode}${number}` === phone.number.replace('+', '') && extension === phone.extension
  }
  return phones.some(matcher)
}

export function checkIfEmailIsDuplicate(newEmail: string, emails: EmailResponse[]) {
  if (!Array.isArray(emails)) return false
  return emails.some((email) => email.address === newEmail)
}

export function checkIfAccountIsDuplicate(newAccountId: string, currentRole: ContactRoleResponse) {
  if (currentRole === null || currentRole === undefined) return false
  return newAccountId === currentRole.accountId
}

export function checkIfIndustryIsDuplicate(industry: { description: string; value: string; label: string }, industries: Industries[]) {
  const industryCodes = industries.map((i) => i.code?.toString())

  return industryCodes.some((code) => industry !== undefined && industry.label.includes(code))
}

export function checkIfAddressIsDuplicate(newAddress: AddressRequest, addresses: AddressResponse[]) {
  // Check if the create form address already exists is the same as any of the existing addresses
  // must be an exact match in every field, a spelling difference is significant in addresses?
  const matcher = (address: AddressResponse) => {
    return (
      address.city === newAddress.city &&
      address.state === newAddress.state &&
      address.country === newAddress.country &&
      address.postalCode === newAddress.postalCode &&
      address.line1 === newAddress.line1 &&
      address.line2 === newAddress.line2
    )
  }

  return addresses.some(matcher)
}
