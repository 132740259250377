import React, { PropsWithChildren } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { ButtonV2, SheetFooter, SheetHeader, SheetTitle, useWizardContext } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { Form } from '../Form'

type Props = {
  newEntityStatus: Status
  hasDuplicates: boolean
  entityType: string
  canSubmit: boolean
  onSubmit: (event: React.BaseSyntheticEvent<object, any, any>) => void
  onReset: () => void
  form: UseFormReturn<any>
}

export function CreateStepDisplay(props: PropsWithChildren<Props>) {
  const { hasDuplicates, entityType, canSubmit, onSubmit, onReset, newEntityStatus, form, children } = props
  const { nextStep } = useWizardContext()

  return (
    <Form form={form} isEditing onSubmit={onSubmit} className="flex h-full flex-col">
      {children}
      <SheetFooter className="mt-auto flex justify-end gap-sm py-12">
        <ButtonV2 size="lg" type="button" variant="secondary" onClick={onReset}>
          Reset
        </ButtonV2>
        {hasDuplicates ? (
          <ButtonV2 size="lg" type="button" variant="primary" onClick={nextStep}>
            See duplicates
          </ButtonV2>
        ) : (
          <ButtonV2 size="lg" type="submit" variant="primary"  className="font-bold" loading={newEntityStatus === 'loading'} disabled={!canSubmit}>
            Save as New {entityType}
          </ButtonV2>
        )}
      </SheetFooter>
    </Form>
  )
}
