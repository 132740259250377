import { useFormContext } from 'react-hook-form'

import { AccountResponse, AddressRequest, NewAccountFormData } from '@app/types'
import { getPhoneDisplayString } from '@app/utils'

import { DataCell, DataColumn, DataRow, MergeTable, SectionHeaderRow, SubHeaderRow } from '../MergeTable'
import { checkIfAddressIsDuplicate, checkIfIndustryIsDuplicate, checkIfPhoneIsDuplicate } from '../utils'
import { AccountMergeFormData } from './CreateAccountWizard'

type Props = {
  account: AccountResponse
  createFormData: NewAccountFormData
}

export function AccountMergeTable({ account, createFormData }: Props) {
  const mergeForm = useFormContext<AccountMergeFormData>()

  const { addresses } = account

  const createFormAddress: AddressRequest = {
    state: createFormData.address.state.label,
    city: createFormData.address.city,
    country: createFormData.address.country.value.name,
    line1: createFormData.address.line1,
    line2: createFormData.address.line2,
    postalCode: createFormData.address.postalCode,
    label: createFormData.address.label.value,
  }

  const renderAddressLabel = (address: AddressRequest) => {
    return (
      <div className="grid">
        <span>{`${address.line1 || ''}, ${address.line2 || ''}`}</span>
        <span>{`${address.city || ''}, ${address.state || ''} ${address.postalCode || ''}`}</span>
        <span>{`${address.country || ''}`}</span>
      </div>
    )
  }

  const phoneData = {
    number: createFormData.phone,
    callingCode: createFormData.callingCode,
    extension: createFormData.extension,
  }

  const createFormPhoneNumber = getPhoneDisplayString(phoneData)

  const isPhoneDuplicate = checkIfPhoneIsDuplicate(phoneData, account.phones)

  const isAddressDuplicate = checkIfAddressIsDuplicate(createFormAddress, account.addresses)

  return (
    <MergeTable>
      <SectionHeaderRow>
        Select <strong>all</strong> information below you would like to keep
      </SectionHeaderRow>
      <SubHeaderRow />
      <DataRow label="Addresses">
        <DataColumn>
          <DataCell>
            <input
              type="checkbox"
              {...mergeForm.register('isNewAddressSelected')}
              id="createFormAddress"
              defaultChecked={!isAddressDuplicate && true}
              disabled={isAddressDuplicate}
            />
            <label htmlFor="createFormAddress">{renderAddressLabel(createFormAddress)}</label>
          </DataCell>
        </DataColumn>
        <DataColumn>
          {addresses.length
            ? addresses.map((address) => (
                <DataCell key={address.id}>
                  <p>{renderAddressLabel(address)}</p>
                </DataCell>
              ))
            : null}
        </DataColumn>
      </DataRow>
      <DataRow label="Phones">
        <DataColumn>
          <DataCell>
            <input
              type="checkbox"
              {...mergeForm.register('isNewPhoneSelected')}
              id="createFormData.phone"
              defaultChecked={!isPhoneDuplicate && true}
              disabled={isPhoneDuplicate}
            />
            <label htmlFor="createFormData.phone">{createFormPhoneNumber}</label>
          </DataCell>
        </DataColumn>
        <DataColumn>
          {account.phones?.map((phone) => (
            <DataCell key={phone.id}>
              <p>{phone.number}</p>
            </DataCell>
          ))}
        </DataColumn>
      </DataRow>
      <DataRow label="Industries">
        <DataColumn>
          {createFormData.industries?.map((industry, index) => {
            const isDuplicate = checkIfIndustryIsDuplicate(industry, account.industries)
            return (
              <DataCell key={index}>
                <input
                  type="checkbox"
                  {...mergeForm.register('isNewIndustrySelected')}
                  id={`${index}-${industry.value}`}
                  defaultChecked={!isDuplicate && true}
                  disabled={isDuplicate}
                />
                <label htmlFor={`${index}-${industry.value}`}>{industry.value}</label>
              </DataCell>
            )
          })}
        </DataColumn>
        <DataColumn>
          {account.industries?.map((industry) => (
            <DataCell key={industry.id}>
              <p>{industry.title}</p>
            </DataCell>
          ))}
        </DataColumn>
      </DataRow>
    </MergeTable>
  )
}
