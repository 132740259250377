const variants = {
  label: {
    base: 'text-sm text-gray-darker pb-xs block',
  },
  input: {
    base: 'text-sm font-regular text-true-black p-xs pl-s border border-solid rounded-xs border-gray focus:outline-none',
  },
  optgroup: {
    base: 'flex flex-col',
  },
}

export const createVariants = { ...variants }

export type CreateProps = {
  navMode?: boolean;
  buttonActive?: boolean;
}

export type CreateStatus = 'idle' | 'pending' | 'success' | 'error'
