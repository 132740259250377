'use client'

import { useMemo } from 'react'

import { useRequestCallback } from '@fsg/next-auth'
import { ENDPOINTS } from '@app/constants'
import { ENTITY } from '@app/types'

export type Transformer = ((data: any) => any) | Partial<Record<'create' | 'update', (data: any) => any>>

export type GetByParamsRequestOptions = {
  search?: string
  page?: number
  limit?: number
  filter?: string
  sort?: string // prefix with `-` for descending sort
}

export type GetByParamsResponseType<EntityResponseType> = {
  data: EntityResponseType[]
  page: number
  limit: number
  totalCount: number
}
// * replacing GetByParamsResponseType
// * already exists as APIResponse in /types/types.ts, but the typing there is somewhat constrained
type APIResponseType<EntityResponseType> = {
  data: EntityResponseType[]
  page: number
  limit: number
  totalCount: number
}

export function useEngageServiceFactory<EntityRequestType, EntityResponseType>(endpoint: string, transformers?: Transformer) {
  const { request } = useRequestCallback()

  const service = useMemo(() => {
    const getTransformedData = (data: Partial<EntityRequestType>, operation: 'create' | 'update') => {
      if (typeof transformers === 'function') return transformers(data)
      if (transformers) return transformers[operation]?.(data) || data
      return data
    }

    type ThenCallback = (results: { fetched: EntityResponseType[]; total: number }) => void
    
    const getAllData = async (
      queryParams: GetByParamsRequestOptions,
      then: ThenCallback = () => {}, // default assignment
      fetched: any[] = [],
      signal?: AbortSignal
    ): Promise<EntityResponseType[]> => {
      const { page, limit = 5000, filter, sort } = queryParams

      const params = new URLSearchParams()
      if (page) params.set('page', page.toString())
      if (filter) params.set('filter', filter)
      if (sort) params.set('sort', sort)
      if (limit) params.set('limit', limit.toString())
      const paramString = params.toString()

      if (signal?.aborted) return fetched;
 
      const data = await request<GetByParamsResponseType<EntityResponseType>>({ path: `${endpoint}?${paramString}`, signal })

      fetched = fetched.concat(data.data)

      const totalPages = Math.ceil(data.totalCount / limit)
      
      const morePagesExist = page < totalPages && fetched.length !== data.totalCount

      then({ fetched, total: data.totalCount })

      if (morePagesExist) {
        return getAllData({ page: page + 1, limit, filter, sort }, then, fetched, signal)
      } else {
        return fetched
      }
    }

    return {
      getAllData,
      getByParams: async ({ search, page, limit = 5000, filter, sort }: GetByParamsRequestOptions) => {
        const params = new URLSearchParams()
        // * search param is deprecated, still available for legacy compatibility
        // * use search() method instead
        if (search) params.set('search', search)
        // * ------------------------------------ *
        if (filter) params.set('filter', filter)
        if (page) params.set('page', page.toString())
        if (limit) params.set('limit', limit.toString())
        if (sort) params.set('sort', sort)
        const paramString = params.toString()

        return request<GetByParamsResponseType<EntityResponseType>>({ path: `${endpoint}?${paramString}` })
      },
      getById: async (id: string) => {
        return request<EntityResponseType>({ path: `${endpoint}/${id}`, method: 'GET' })
      },
      create: async (data: EntityRequestType) => {
        return request<EntityResponseType>({ path: endpoint, method: 'POST', body: JSON.stringify(getTransformedData(data, 'create')) })
      },
      patchById: async (data: Partial<EntityRequestType>, id: string) => {
        return request<EntityResponseType>({ path: `${endpoint}/${id}`, method: 'PATCH', body: JSON.stringify(data) })
      },
      updateById: async (data: Partial<EntityRequestType>, id: string) => {
        const transformedData: Partial<EntityRequestType> = getTransformedData(data, 'update')
        return request<EntityResponseType>({ path: `${endpoint}/${id}`, method: 'PUT', body: JSON.stringify(transformedData) })
      },
      deleteById: async (id: string) => {
        return request<EntityResponseType>({ path: `${endpoint}/${id}`, method: 'DELETE' })
      },
      search: async (entity: ENTITY, query: string) => {
        return request<APIResponseType<EntityResponseType>>({ path: ENDPOINTS.ENTITY_SEARCH(entity, query)})
      }
    }
  }, [endpoint, request, transformers])

  return service
}
