import React from 'react'
import { useRouter } from 'next/navigation'
import { FieldErrors } from 'react-hook-form'

import { useNotification, useWizardContext } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { AccountResponse, NewAccountFormData } from '@app/types'

import { DuplicateMatchesData } from '..'
import { CreateStepDisplay } from '../CreateStepDisplay'
import { AccountWizardContext } from './CreateAccountWizard'
import { NewAccountForm } from './NewAccountForm'

type Props = {
  createNewAccount: () => Promise<void>
  newAccountStatus: Status
  setNewAccountStatus: React.Dispatch<React.SetStateAction<Status>>
}

export function CreateStep(props: Props) {
  const router = useRouter()

  const notification = useNotification()

  const { createNewAccount, newAccountStatus, setNewAccountStatus } = props

  const {
    duplicateMatches: { matches, setMatches, getMatches },
    setSelectedMatch,
    entityType,
    nextStep,
    createForm,
    getSuccessPath,
  } = useWizardContext<AccountWizardContext>()

  const canSubmit = matches === undefined && newAccountStatus !== 'loading'

  function handleFoundMatches(matches: DuplicateMatchesData<AccountResponse>) {
    setNewAccountStatus('idle')
    setMatches(matches)
    setSelectedMatch(matches.exact?.[0] || matches.fuzzy?.[0])
    nextStep()
  }

  async function onSubmit(data: NewAccountFormData) {
    setNewAccountStatus('loading')

    try {
      const matches = await getMatches(data)
      if (matches === undefined) throw new Error('Error getting matches')
      const hasDuplicates = Boolean(matches?.exact?.length || matches?.fuzzy?.length)
      if (hasDuplicates) {
        handleFoundMatches(matches)
        // Note: exit submit process early if there are any matches
        return
      } else {
        await createNewAccount()
      }
    } catch (e) {
      console.log(e)
      setNewAccountStatus('error')
    }
  }

  function onSubmitError(errors: FieldErrors) {
    notification.api.notify({
      message: 'Some fields are missing or incorrect',
      type: 'warning',
    })
    console.error(errors)
  }

  function resetCreateStep() {
    createForm.reset()
    setMatches(undefined)
    setNewAccountStatus('idle')
  }

  const hasDuplicates = Boolean(matches?.exact?.length || matches?.fuzzy?.length)

  return (
    <CreateStepDisplay
      newEntityStatus={newAccountStatus}
      hasDuplicates={hasDuplicates}
      entityType={entityType}
      canSubmit={canSubmit}
      onSubmit={createForm.handleSubmit(onSubmit, onSubmitError)}
      onReset={resetCreateStep}
      form={createForm}
    >
      <NewAccountForm form={createForm} />
    </CreateStepDisplay>
  )
}
