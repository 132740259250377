import { ButtonV2, Controls, SheetFooter, useWizardContext } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { DuplicateMatchesData } from '..'
import { DuplicateSelectionStepDisplay } from '../DuplicateSelectionStepDisplay'
import { ContactWizardContext } from './CreateContactWizard'

type Props<EntityResponse> = {
  matches: DuplicateMatchesData<EntityResponse>
  entityType: string
  saveAsNewEntity: () => Promise<void>
  renderEntityMatch: (entity: EntityResponse) => JSX.Element
  newEntityStatus: Status
  setNewEntityStatus: React.Dispatch<React.SetStateAction<Status>>
}

export function DuplicatesSelectionStep<E>(props: Props<E>) {
  const { matches, saveAsNewEntity, renderEntityMatch, newEntityStatus, entityType } = props

  const { prevStep, nextStep } = useWizardContext<ContactWizardContext>()

  const controls = (
    <Controls>
      <ButtonV2 type="button" variant="secondary" size="lg" onClick={prevStep}>
        Previous
      </ButtonV2>
      <ButtonV2
        size="lg"
        variant="secondary"
        type="button"
        onClick={saveAsNewEntity}
        loading={newEntityStatus === 'loading'}
        disabled={newEntityStatus === 'loading'}
      >
        Save as New {entityType}
      </ButtonV2>
      <ButtonV2 variant="primary" type="button" size="lg" onClick={nextStep}>
        Next
      </ButtonV2>
    </Controls>
  )

  return (
    <div className="flex h-full flex-col">
      <DuplicateSelectionStepDisplay entityType={entityType} matches={matches} renderMatchCallback={renderEntityMatch} />
      <SheetFooter className="mt-auto">{controls}</SheetFooter>
    </div>
  )
}
