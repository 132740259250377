import { User } from '@fsg/next-auth/types'

export function checkCampaignAuthorization(user: User): boolean {
  // ? Primary authorization check should be based on division/department
  if (user?.division !== undefined && user?.division !== null && user?.department !== undefined && user?.department !== null) {
    return (user.division === 99 && (user?.department === 93 || user?.department === 95)) || authorizedUsers.includes(user?.email) //temp leaving the email check here because my department is wrong - CR
  }

  // Fallback method
  // If ERP data is incorrect but the user still should have access, check whitelisted emails
  return authorizedUsers.includes(user?.email)
}

export const authorizedUsers = [
  'david.attardi@fsgi.com',
  'ryan.olson@fsgi.com',
  'marco.ortes@fsgi.com',
  'chris.roach@fsgi.com',
  'jon.magee@fsgi.com',
  'daryl.schneider@fsgi.com',
  'chris.allen@fsgi.com',
  'adam.haynes@fsgi.com',
  'matthew.mccallon@fsgi.com',
  'billg@fsgi.com',
  'robert.davis@fsgi.com',
  'david.loughborough@fsgi.com',
  'anthony.perry@fsgi.com',
  'mike.cooper@fsgi.com',
  'brannon.bourland@fsgi.com',
  'tony.barrenda@fsgi.com',
  'daniel.villegas@fsgi.com',
  'jonathan.baker@fsgi.com',
  'allenb@fsgi.com',
  'shelly.nixon@fsgi.com',
  'stacy.justice@fsgi.com',
  'bobby.graham@fsgi.com',
  'marcus.dunaway@fsgi.com',
  'aaron.davis@fsgi.com'
]
