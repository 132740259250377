import { useEffect, useMemo, useState } from 'react'

import { SitesQuery } from '@app/api/EntityQuery'
import { FilterQueryParams } from '@app/api/FilterQueryParams'
import { useSiteService } from '@app/hooks'
import { AccountResponse, SiteResponse } from '@app/types'

type SiteNameProps = {
  account: AccountResponse
  customerSiteId: string
  siteName: string
}

export type SiteNameGeneratorProps = {
  generateName: () => string
  generateCopy: (finalName: string) => Promise<string>
  sites: SiteResponse[]
}

export function useSiteNameGenerator({ account, customerSiteId, siteName }: SiteNameProps): SiteNameGeneratorProps {
  const [generatedName, setGeneratedName] = useState<string>('')
  const [sites, setSites] = useState<SiteResponse[]>([])
  const service = useSiteService()
  const fq = new FilterQueryParams()

  const fetchSites = async () => {
    const ownerQuery = fq.textFilter({ field: 'owner.id', value: account.id, operation: 'equals' })
    const nameQuery = fq.textFilter({ field: 'name', value: generatedName, operation: 'contains' })
    const filterGroup = fq.group(fq.and(ownerQuery, nameQuery))
    const data = await service.getAllData({ page: 1, limit: 5000, filter: filterGroup })
    setSites(data)
    return data
  }

  const generateName = (): string => {
    let initialName = `${account.name} - ${customerSiteId}`
    setGeneratedName(initialName)
    return initialName
  }

  const generateCopy = async (finalName: string) => {
    const filteredSites = await fetchSites()
    let existingCopies = filteredSites.filter((f) => f.name.toLowerCase().startsWith(finalName.toLowerCase()))

    if (existingCopies.length === 0) {
      return finalName
    }

    let existingCopyNumbers = existingCopies.map((f) => {
      let match = f.name.match(/\((\d+)\)$/)
      return match ? parseInt(match[1], 10) : 0
    })

    let nextCopyNumber = Math.max(...existingCopyNumbers) + 1
    let newName = `${finalName} (${nextCopyNumber})`
    return newName
  }

  return { generateName, generateCopy, sites }
}
