'use client'

import { useEffect, useState } from 'react'

export function useFirstLoginCheck() {
  const [isFirstTime, setIsFirstTime] = useState(false)

  useEffect(() => {
    if (window === undefined) return
    
    const key = 'isFirstLogin'
    // Check if the key exists in local storage
    const hasLoggedInBefore = localStorage.getItem(key)

    if (!hasLoggedInBefore) {
      // If the key doesn't exist, it's the first time the user has logged in
      setIsFirstTime(true)
      // Set the key in local storage to mark that the user has logged in before
      localStorage.setItem(key, 'true')
    }
  }, [])

  return isFirstTime
}
