import { CardButton, RadioInput } from '@fsg/gui-bits'
import classNames from 'classnames/bind'
import { ContactResponse } from '@app/types'
import { styles as duplicateCardStyles } from '../ui/styles'
import moduleStyles from '../ui/DuplicateCard.module.scss'

const { duplicateCard: styles } = duplicateCardStyles
const cx = classNames.bind(moduleStyles)

export function DuplicateContactCard({
  contact,
  selectedMatchId,
  onClickCard,
}: {
  contact: ContactResponse
  selectedMatchId: string
  onClickCard: (contact: ContactResponse) => void
}) {
  const handleRadioClick = (e: React.MouseEvent<HTMLInputElement>) => {
    // Stop the click event from propagating to the parent CardButton
    e.stopPropagation()
    onClickCard(contact)
  }

  return (
    <CardButton key={contact.id} className={cx('m--card', styles.cardButton, { 'm--card-selected': selectedMatchId === contact.id })} onClick={() => onClickCard(contact)}>
      <div className={styles.cardHeader.wrapper}>
        <p className={styles.cardHeader.title}>{contact.fullName}</p>
        <RadioInput
          name="selectedContactMatch"
          checked={selectedMatchId === contact.id}
          id={contact.id}
          className={styles.cardHeader.radioInput}
          onClick={handleRadioClick}
        />
      </div>
      <div>
        <ul>
          {contact.currentRole ? (
            <li>
              <span className={styles.listItemBold}>
                <strong>{contact.currentRole.accountName}</strong>
              </span>
            </li>
          ) : null}
          {contact.primaryEmail ? (
            <li>
              <span className={styles.listItem}>{contact.primaryEmail?.address}</span>
            </li>
          ) : null}
          {contact.primaryPhone ? (
            <li>
              <span className={styles.listItem}>{contact.primaryPhone?.number}</span>
            </li>
          ) : null}
          {contact.currentRole ? (
            <li>
              <span className={styles.listItem}>{contact.currentRole.title}</span>
            </li>
          ) : null}
        </ul>
      </div>
    </CardButton>
  )
}
