import { CSSObjectWithLabel, StylesConfig } from 'react-select'

export const styles = {
  duplicateCard: {
    cardButton: 'group flex min-h-[100px] flex-col py-3xs text-xs',
    cardHeader: {
      wrapper: 'flex justify-between items-start mb-md',
      title: 'text-sm font-semibold',
      radioInput: 'text-color-primary group-hover:text-color-primary',
    },
    listItem: 'text-gray-darkest text-[12px]',
    listItemBold: 'text-gray-darkest text-[12px] font-semibold',
    address: 'not-italic',
  },
}
