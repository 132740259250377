import { AccountResponse } from 'types/accounts'

import { ButtonV2, Controls, SheetFooter, useWizardContext } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { DuplicateMatchesData } from '..'
import { DuplicateSelectionStepDisplay } from '../DuplicateSelectionStepDisplay'

type Props = {
  matches: DuplicateMatchesData<AccountResponse>
  entityType: string
  createNewAccount: () => void
  renderAccountMatch: (entity: AccountResponse) => JSX.Element
  newAccountStatus: Status
}

export function AccountDuplicatesStep(props: Props) {
  const { matches, renderAccountMatch, entityType, createNewAccount, newAccountStatus } = props
  const { prevStep, nextStep } = useWizardContext()

  const controls = (
    <Controls>
      <ButtonV2 type="button" variant="secondary" size="lg" onClick={prevStep}>
        Previous
      </ButtonV2>
      <ButtonV2
        size="lg"
        variant="secondary"
        type="button"
        onClick={createNewAccount}
        loading={newAccountStatus === 'loading'}
        disabled={newAccountStatus === 'loading'}
      >
        Save as New {entityType}
      </ButtonV2>
      <ButtonV2 variant="primary" type="button" size="lg" onClick={nextStep}>
        Next
      </ButtonV2>
    </Controls>
  )

  return (
    <div className="flex h-full flex-col">
      <DuplicateSelectionStepDisplay entityType={entityType} matches={matches} renderMatchCallback={renderAccountMatch} />
      <SheetFooter className="mt-auto">{controls}</SheetFooter>
    </div>
  )
}
