import React, { useState } from 'react'

import { ButtonV2 } from '@fsg/gui-bits'

import { DuplicateMatchesData } from '.'
import { DuplicateCardGrid } from './DuplicateCardGrid'

type Props<EntityResponse> = {
  entityType: string
  matches: DuplicateMatchesData<EntityResponse>
  renderMatchCallback: (entity: EntityResponse) => JSX.Element
}

export function DuplicateSelectionStepDisplay<E>(props: Props<E>) {
  const { entityType, matches, renderMatchCallback } = props
  const exactMatchesExist = Boolean(matches.exact.length)
  const fuzzyMatchesExist = Boolean(matches.fuzzy.length)

  const [showFuzzyMatches, setShowFuzzyMatches] = useState(fuzzyMatchesExist ? true : false)

  const toggleFuzzyMatchDisplay = () => setShowFuzzyMatches((prev) => !prev)

  /* 
  * Total of 10 matches between exact AND fuzzy should be displayed, with exact matches taking precedence
  AKA: 
  Available spots = 10
  10 = exact + fuzzy; fuzzy = 10 - exact
  
  */
  const fuzzyMatchesToShow = 10 - matches.exact.length

  return (
    <>
        <em className="text-sm text-gray-darker">This {entityType} may already exist. Select one of the following to update it:</em>
        {exactMatchesExist ? (
          <div className="w-full pb-12">
            <p className="my-sm text-sm">Showing exact Matches</p>
            <DuplicateCardGrid>{matches.exact?.slice(0, 10).map(renderMatchCallback)}</DuplicateCardGrid>
          </div>
        ) : null}
        {fuzzyMatchesExist ? (
          <div className="w-full pb-12">
            {fuzzyMatchesExist ? null : (
              <ButtonV2 variant="ghost" size="md" className="my-sm" onClick={toggleFuzzyMatchDisplay}>
                {showFuzzyMatches ? 'Show less' : 'Show more'}
              </ButtonV2>
            )}
            <p className="my-sm text-sm">Showing fuzzy Matches</p>
            {showFuzzyMatches && matches.exact.length < 10 ? <DuplicateCardGrid>{matches.fuzzy?.slice(0, fuzzyMatchesToShow).map(renderMatchCallback)}</DuplicateCardGrid> : null}
          </div>
        ) : null}
    </>
  )
}
