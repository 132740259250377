'use client'

import { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/navigation'
import { useSession } from 'next-auth/react'
import { addEditActivity, addActivityNote, addViewActivity, getActivities } from 'src/lib/utils'
import { ActivityResponse } from 'types/types'

import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { UseFormReturn } from '@app/components/AppForm'
import { ACTIVITY_ENTITIES, ACTIVITY_TYPES } from '@app/constants'
import { formatCamelCaseLabel } from '@app/utils'


export function useActivityTracking<T = any>(appForm: UseFormReturn<T>, entity: ACTIVITY_ENTITIES, id: string) {

  const router = useRouter()
  const { data: sessionData } = useSession()
  const { request } = useRequestCallback()

  const user = useMemo(() => {
    return sessionData.user
  }, [sessionData])

  const { form } = appForm

  const logEditActivity = async () => {
    const { isDirty, dirtyFields } = form.formState
    const editedFields = Object.keys(dirtyFields)
    const parsedFields = editedFields
      .filter((field) => field !== 'id' && field !== 'createdAt' && field !== 'updatedAt')
      .map((field) => ` ${formatCamelCaseLabel(field)}`)

    // * If form is not dirty, no need to log an edit activity
    if (!isDirty || parsedFields.length === 0) return

    const activity = await addEditActivity(request, entity, id)
    const content = `Fields Edited:${parsedFields.toString()}`
    await addActivityNote(request, activity.id, content)
    router.refresh()
  }

  // chains view activity to whether or not the ID in the url changes (preventing multiple view logs for the path changing while user is clicking in other pages of an entity)
  const handleViewActivity = useCallback(async () => {
    function isToday(input: Date | string): boolean {
      const today = new Date()
      if (typeof input === 'string') {
        input = new Date(input)
      }
      return input.getDate() === today.getDate() && input.getMonth() === today.getMonth() && input.getFullYear() === today.getFullYear()
    }

    if (id !== undefined) {
      const recentActivities = await getActivities(request<any>, entity, id)
      const sortedActivities = recentActivities.data.sort((a: ActivityResponse, b: ActivityResponse) => {
        let dateA = new Date(a.recordedAt).getTime()
        let dateB = new Date(b.recordedAt).getTime()

        return dateB - dateA
      })
      const lastActivity = sortedActivities.find((activity: ActivityResponse) => {
        return (activity.id === user?.id || activity.user?.email === user?.email) && activity.activityType === ACTIVITY_TYPES.VIEW_ONLY
      })

      if (lastActivity && isToday(lastActivity.recordedAt)) {
        const content = 'Recurring view'
        await addActivityNote(request, lastActivity.id, content)
      } else {
        const activity = await addViewActivity(request, entity, id)
        const content = 'First view'
        router.refresh()
        await addActivityNote(request, activity.id, content)
      }
      router.refresh()
    }
  }, [entity, request, router, user?.email, user?.id, id])

  useEffect(() => {
    handleViewActivity()
  }, [handleViewActivity])

  return { logEditActivity }
}
