'use client';

import React from 'react';
import { ErrorMessage } from '@hookform/error-message'
import { UseFormReturn } from 'react-hook-form'
import { useAppContextSelector } from 'src/app-components/AppContext'

import { useRequestOnMount } from '@fsg/next-auth/useRequest'

import { SelectField } from '@app/components/Form'
import { AccountLookup } from '@app/components/Lookups/AccountLookup'
import { CustomerContactLookup } from '@app/components/Lookups/CustomerContactLookup'
import { EmployeeContactLookup } from '@app/components/Lookups/EmployeeContactLookup'
import { ENDPOINTS, VALIDATIONS } from '@app/constants'
import { NewOpportunityFormData } from '@app/types'
import { formatDivisionOption, formatENUMOptions, renderErrorMessage } from '@app/utils'

import { Field, FieldGroup } from '../ui'

type Props = {
  form: UseFormReturn<NewOpportunityFormData, any, undefined>
  defaultValues?: Partial<NewOpportunityFormData>
}

export function NewOpportunityForm({ form, defaultValues }: Props) {
  const { data: divisions, status: divisionsStatus } = useAppContextSelector((state) => state.divisions)

  const salesTypeEnums = useRequestOnMount({ path: ENDPOINTS.OPPORTUNITIES_ENUMS })

  const salesTypeTableName = 'salesTypes'

  const enumsOptions = salesTypeEnums.data?.hasOwnProperty(salesTypeTableName)
    ? formatENUMOptions((salesTypeEnums.data as any)[salesTypeTableName])
    : []

  return (
    <div className="grid">
      <FieldGroup title="Basic Information" description="Please provide an easy-to-reference name for this opportunity.">
        <Field label="Name" {...form.register('name', { required: VALIDATIONS.REQUIRED })} errors={form.formState.errors} />
      </FieldGroup>

      <FieldGroup title="Customer" description="Please specify the company and main contact associated with this opportunity.">
        <AccountLookup label="Customer" name="accountId" rules={{ required: VALIDATIONS.REQUIRED }} />
        <ErrorMessage errors={form.formState.errors} name="accountId" render={renderErrorMessage} />
        <CustomerContactLookup name="primaryStakeholderId" label="Primary Customer Stakeholder" rules={{ required: VALIDATIONS.REQUIRED }} />
      </FieldGroup>

      <FieldGroup title="Interests" description="Please specify the overall type of opportunity represented.">
        <SelectField
          name="salesType"
          label="Sales Type"
          options={enumsOptions}
          rules={{ required: VALIDATIONS.REQUIRED }}
          placeholder="Select a sales type..."
          isClearable
          isLoading={salesTypeEnums.status === 'loading'}
        />
        <ErrorMessage errors={form.formState.errors} name="salesType" render={renderErrorMessage} />
      </FieldGroup>

      <FieldGroup
        title="Team Details"
        description="Please specify the FSG Division and Account Manager who will have primary ownership of this opportunity."
      >
        <SelectField
          label={VALIDATIONS.styleLabel('Division')}
          name="division"
          rules={{ required: VALIDATIONS.REQUIRED }}
          options={divisions.map(formatDivisionOption)}
          isLoading={divisionsStatus === 'loading'}
          isClearable
        />
        <EmployeeContactLookup label="Account Manager" name="accountPortfolioManagerId" rules={{ required: VALIDATIONS.REQUIRED }} />
      </FieldGroup>
    </div>
  )
}