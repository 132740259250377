import React, { useState } from 'react'
import { useRouter } from 'next/navigation'
import { FieldErrors, useForm } from 'react-hook-form'

import { ButtonV2, SheetContent, SheetFooter, SheetHeader, SheetTitle, useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { Form } from '@app/components/Form'
import { useCampaignsService } from '@app/hooks'
import { CampaignResponse, NewCampaignFormData } from '@app/types'
import { preventSheetClose, routes } from '@app/utils'

import NewCampaignForm from './NewCampaignForm'

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function CreateCampaign({ setOpen }: Props) {
  const notification = useNotification()
  const router = useRouter()
  const service = useCampaignsService()

  const createForm = useForm<NewCampaignFormData>({
    defaultValues: {
      name: '',
      ownerId: null,
      type: null,
      description: '',
      division: null,
      plannedCost: 0,
      expectedRevenue: 0,
      plannedStart: '',
      plannedEnd: '',
    },
  })

  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)

  const canSubmit = status !== 'loading'

  const closeSheet = () => setOpen(false)
  function handleSuccessfulCreate(response: CampaignResponse) {
    setStatus('success') // we probably don't need a Success state
    createForm.reset()
    closeSheet()
    router.push(routes.campaign.general(response.id))
  }

  const entityType = 'Campaign'

  function handleFailedCreate(error: any) {
    setError(error as Error)
    setStatus('error')
    notification.api.notify({
      message: `Error Creating ${entityType} - ${error.message}`,
      type: 'warning',
    })
  }

  async function onSubmit(data: NewCampaignFormData) {
    setStatus('loading')
    try {
      const response = await service.create(data)
      handleSuccessfulCreate(response)
    } catch (error: any) {
      handleFailedCreate(error)
    }
  }

  function onSubmitError(errors: FieldErrors) {
    notification.api.notify({
      message: 'Some fields are missing or incorrect',
      type: 'warning',
    })
    console.error(errors)
  }

  return (
    <SheetContent
      side="right"
      className="w-[600px]"
      onInteractOutside={(event) => {
        preventSheetClose(event)
      }}
    >
      <Form form={createForm} isEditing onSubmit={createForm.handleSubmit(onSubmit, onSubmitError)} className="flex h-full flex-col">
        <SheetHeader className="pb-4">
          <SheetTitle className="text-4xl">Create {entityType}</SheetTitle>
        </SheetHeader>
        <NewCampaignForm form={createForm} />
        <SheetFooter className="flex justify-end gap-sm py-12">
          <ButtonV2 size="lg" type="button" variant="secondary" onClick={() => createForm.reset()}>
            Reset
          </ButtonV2>
          <ButtonV2 size="lg" type="submit" variant="primary" className="font-bold" loading={status === 'loading'} disabled={!canSubmit}>
            Save as New {entityType}
          </ButtonV2>
        </SheetFooter>
      </Form>
    </SheetContent>
  )
}
