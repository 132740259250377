import classNames from 'classnames/bind'

import { CardButton, RadioInput } from '@fsg/gui-bits'

import { AccountResponse } from '@app/types'

import moduleStyles from '../ui/DuplicateCard.module.scss'
import { styles as duplicateCardStyles } from '../ui/styles'

const { duplicateCard: styles } = duplicateCardStyles
const cx = classNames.bind(moduleStyles)

export function DuplicateAccountCard({
  account,
  selectedMatchId,
  onClickCard,
}: {
  account: AccountResponse
  selectedMatchId: string
  onClickCard: (account: AccountResponse) => void
}) {
  const handleRadioClick = (e: React.MouseEvent<HTMLInputElement>) => {
    // Stop the click event from propagating to the parent CardButton
    e.stopPropagation()
    onClickCard(account)
  }

  return (
    <CardButton
      key={account.id}
      className={cx('m--card', styles.cardButton, { 'm--card-selected': selectedMatchId === account.id })}
      onClick={() => onClickCard(account)}
    >
      <div className={styles.cardHeader.wrapper}>
        <p className={styles.cardHeader.title}>{account.name}</p>
        <RadioInput
          name="selectedAccountMatch"
          checked={selectedMatchId === account.id}
          id={account.id}
          className="text-color-primary group-hover:text-color-primary"
          onClick={handleRadioClick}
        />
      </div>
      {account.primaryAddress ? (
        <address className={styles.address}>
          {account.primaryAddress.line1}
          {account.primaryAddress.line2 ? <br /> : null}
          {account.primaryAddress.line2}
          <br />
          {account.primaryAddress.city}, {account.primaryAddress.state} {account.primaryAddress.postalCode}
        </address>
      ) : null}
      {/* <div className="text-xs">
        <ul>
          {account.phones ? (
            <li>
              <span className={styles.listItemBold}>
                {account.phones?.length ||
                  account.phones.map((phone) => (
                    <>
                      {phone.isPrimary ? 'Primary:' : `${phone.label}:`} <strong>{phone.number}</strong>
                    </>
                  ))}
              </span>
            </li>
          ) : null}
        </ul>
      </div> */}
    </CardButton>
  )
}
