import { AccountRequest, AccountResponse, NewAccountFormData } from '@app/types'

import { AccountMergeFormData } from '.'

export function transformAccountMergeData({
  createFormData,
  mergeFormData,
  account,
}: {
  createFormData: NewAccountFormData
  mergeFormData: AccountMergeFormData
  account: AccountResponse
}): AccountRequest {
  // ? Transformations include id properties type explicitly as undefined in order to satisfy AccountResponse type

  const request: AccountRequest = {
    name: account.name,
    phones: account.phones,
    addresses: account.addresses,
    industries: account.industries,
  }

  if (mergeFormData.isNewPhoneSelected) {
    const newPhoneNumber = `+${createFormData.callingCode.value.telephoneCode}${createFormData.phone}`
    const newPhoneRequest = {
      number: newPhoneNumber,
      label: 'Phone',
      isPrimary: isCollectionEmpty(account.phones),
      id: undefined as any,
      extension: createFormData.extension,
    }
    request.phones.push(newPhoneRequest)
  }

  if (mergeFormData.isNewAddressSelected) {
    const newAddress = {
      id: undefined as any,
      state: createFormData.address.state.value,
      city: createFormData.address.city,
      country: createFormData.address.country.value.name,
      line1: createFormData.address.line1,
      line2: createFormData.address.line2,
      postalCode: createFormData.address.postalCode,
      label: createFormData.address.label.value,
      isPrimary: isCollectionEmpty(account.addresses),
    }
    request.addresses.push(newAddress)
  }

  if (mergeFormData.isNewIndustrySelected) {
    const newIndustryRequest = createFormData.industries ? createFormData.industries.map((industry) => ({ title: industry.value })) : []
    request.industries.push(...(newIndustryRequest as any))
  }

  return request
}

const isCollectionEmpty = (array: any[]) => (array.length === 0 ? true : false)
