'use client'

import React from 'react'

import { ButtonV2, Sheet, SheetTrigger } from '@fsg/gui-bits'

import { CreateCampaign } from './Campaign/CreateCampaign'
import { CreateProps } from './constants'
import { SheetProxyTrigger } from './SheetProxyTrigger'

export function CreateCampaignSheet({ navMode, buttonActive }: CreateProps) {
  const [open, setOpen] = React.useState(false)

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {navMode ? (
        <SheetProxyTrigger buttonActive={buttonActive} />
      ) : (
        <ButtonV2 asChild className="font-bold" variant="primary" size="lg">
          <SheetTrigger>Create Campaign</SheetTrigger>
        </ButtonV2>
      )}
      <CreateCampaign setOpen={setOpen} />
    </Sheet>
  )
}
