'use client'

import React, { forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import camelCase from 'just-camel-case'
import { FieldErrors, FieldValues } from 'react-hook-form'

import { cn } from '@fsg/utils'

import { VALIDATIONS } from '@app/constants'
import { renderErrorMessage } from '@app/utils'

import { createVariants } from '../constants'

interface FieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  errors: FieldErrors<FieldValues>
  requiredLabel?: boolean
}

export const Field = forwardRef<HTMLInputElement, FieldProps>((props, ref) => {
  const { label, errors, ...inputProps } = props
  const id = inputProps.name || camelCase(label)

  return (
    <>
      <label htmlFor={id} className={cn(createVariants.label.base, { 'after:text-color-error after:content-["_*"]': props.requiredLabel })}>
        {label}
      </label>
      <input type="text" id={id} className={createVariants.input.base} {...inputProps} ref={ref} />
      <ErrorMessage errors={errors} name={id} render={renderErrorMessage} />
    </>
  )
})

Field.displayName = 'Field'
