import { compileRolesArray } from 'src/lib/utils';

import { ContactRequest, ContactResponse, EmailRequest, NewContactFormData } from '@app/types'

import { checkIfPhoneIsDuplicate } from '../utils'
import { ContactMergeFormData } from './CreateContactWizard'

export function transformMergedContactData({
  mergeFormData,
  createFormData,
  contact,
}: {
  mergeFormData: ContactMergeFormData
  createFormData: NewContactFormData
  contact: ContactResponse
}) {
  const request: ContactRequest = {
    firstName: mergeFormData.firstName,
    lastName: mergeFormData.lastName,
    contactType: contact.contactType?.id,
    phones: contact.phones,
    emails: contact.emails,
    roles: contact.roles,
  }

  if (mergeFormData.isNewPhoneSelected) {
    const newPhoneNumber = `+${createFormData.callingCode.value.telephoneCode}${createFormData.primaryPhone}`
    const newPhoneRequest = {
      number: newPhoneNumber,
      label: 'Phone',
      extension: createFormData.extension,
      isPrimary: isCollectionEmpty(request.phones),
    }

    const isPhoneDuplicate = checkIfPhoneIsDuplicate(
      {
        number: createFormData.primaryPhone,
        callingCode: createFormData.callingCode,
        extension: createFormData.extension,
      },
      request.phones,
    )
    if (isPhoneDuplicate) return

    request.phones.push(newPhoneRequest)
  }

  if (mergeFormData.isNewEmailSelected) {
    const newEmailRequest: EmailRequest = { address: createFormData.primaryEmail, label: 'Email', isPrimary: isCollectionEmpty(contact.emails) }
    request.emails.push(newEmailRequest)
  }

  if (mergeFormData.isNewAccountSelected) {
    const accountIsDuplicate = createFormData.role.accountId.value === contact.currentRole?.accountId
    if (accountIsDuplicate) return

    request.roles = compileRolesArray({ currentCompany: createFormData.role.accountId, isCurrent: true }, contact.roles)
  }

  return request as ContactRequest
}

const isCollectionEmpty = (array: any[]) => (array.length === 0 ? true : false)