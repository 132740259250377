import { HTMLAttributes, PropsWithChildren } from 'react'

export function SectionHeaderRow(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="p-lg text-sm text-gray-darker">{props.children}</div>
}

export function SubHeaderRow() {
  return (
    <div className="grid w-full grid-cols-2 bg-gray-light p-sm text-sm font-bold text-gray-darker">
      <span>New Information You Provided</span>
      <span>Existing Information</span>
    </div>
  )
}

interface DataRowProps extends PropsWithChildren {
  label: string
}

export function DataRow({ label, children }: DataRowProps) {
  return (
    <div className="border-b last:border-b-0">
      <h3 className="border-b px-sm py-3xs text-sm">{label}</h3>
      <div className="grid grid-cols-2">{children}</div>
    </div>
  )
}

export function DataColumn(props: PropsWithChildren) {
  return <div className="flex flex-col">{props.children}</div>
}

export function DataCell(props: PropsWithChildren) {
  return <div className="flex items-center gap-sm p-sm text-sm">{props.children}</div>
}

export function MergeTable(props: PropsWithChildren) {
  return <div className="rounded-xl border">{props.children}</div>
}
