'use client'

import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { useSession } from 'next-auth/react'
import { Controller, UseFormReturn } from 'react-hook-form'
import Select from 'react-select'
import { useAppContextSelector } from 'src/app-components/AppContext'
import { DateField } from 'src/app-components/Form'

import { useRequestOnMount } from '@fsg/next-auth/useRequest'

import { EmployeeContactLookup } from '@app/components/Lookups/EmployeeContactLookup'
import { ENDPOINTS, VALIDATIONS } from '@app/constants'
import { CampaignEnums, NewCampaignFormData } from '@app/types'
import { formatCampaignENUMS, formatContactOption, formatDivisionOption, getSelectStyles, onBlurWorkaround, renderErrorMessage } from '@app/utils'

import { Field, FieldGroup, Label } from '../ui'

type Props = {
  form: UseFormReturn<NewCampaignFormData>
}

export default function NewCampaignForm({ form }: Props) {
  const { data: sessionData } = useSession()

  const { data: enums, status: enumsStatus, error: enumsError } = useRequestOnMount<CampaignEnums>({ path: ENDPOINTS.CAMPAIGNS_ENUMS })
  const {
    register,
    formState: { errors },
  } = form
  const { user } = sessionData

  const userContact = useAppContextSelector((state) => state.userContact)
  const { data: divisions, status: divisionsStatus } = useAppContextSelector((state) => state.divisions)
  const [defaults, setDefaults] = useState(false)

  useEffect(() => {
    const dataIsReady = divisionsStatus === 'success' && userContact
    if (dataIsReady && !defaults) {
      const division = divisions.find(({ number: divisionNumber }) => divisionNumber == user?.division.toString())
      const divisionOption = division !== undefined ? formatDivisionOption(division) : null

      const defaultValues: Record<string, any> = {
        name: '',
        ownerId: userContact ? formatContactOption(userContact) : undefined,
        type: null,
        description: '',
        division: divisionOption,
        plannedCost: 0,
        expectedRevenue: 0,
        plannedStart: '',
        plannedEnd: '',
      }

      form.reset(defaultValues)
      setDefaults(true)
    }
  }, [userContact, defaults, form, divisions, divisionsStatus, user?.division])

  return (
    <div className="grid">
      <FieldGroup title="General">
        <Field label="Name" {...register('name', { required: VALIDATIONS.REQUIRED })} errors={errors} />
        <div>
          <Label htmlFor="description" label="Description" />
          <Controller
            name="description"
            control={form.control}
            render={({ field }) => (
              <div className="inline-flex w-full rounded-md border border-solid border-gray px-4 py-4 text-sm text-true-black font-regular">
                <textarea className="w-full focus:outline-none" rows={5} {...field} required={false} />
              </div>
            )}
          />
        </div>
        <div>
          <EmployeeContactLookup name="ownerId" label="Owner" rules={{ required: VALIDATIONS.REQUIRED }} isClearable />
        </div>
        <div>
          <Label htmlFor="type" label="Type" />
          <Controller
            control={form.control}
            name="type"
            render={({ field }) => (
              <Select
                {...field}
                {...getSelectStyles()}
                isClearable
                placeholder="Start typing to search..."
                onBlur={onBlurWorkaround}
                isLoading={enumsStatus === 'loading'}
                options={enumsStatus === 'success' ? formatCampaignENUMS(Object.values(enums.campaignType)) : []}
              />
            )}
          />
          <ErrorMessage errors={form.formState.errors} name="type" render={renderErrorMessage} />
        </div>
        <div>
          <Label htmlFor="focus" label="Focus" />
          <Controller
            control={form.control}
            name="focus"
            render={({ field }) => (
              <Select
                {...field}
                {...getSelectStyles()}
                isClearable
                placeholder="Start typing to search..."
                onBlur={onBlurWorkaround}
                isLoading={enumsStatus === 'loading'}
                options={enumsStatus === 'success' ? formatCampaignENUMS(Object.values(enums.campaignFocus)) : []}
              />
            )}
          />
          <ErrorMessage errors={form.formState.errors} name="type" render={renderErrorMessage} />
        </div>
        <div>
          <Label htmlFor="division" label="Division" />
          <Controller
            control={form.control}
            name="division"
            render={({ field }) => (
              <Select
                {...field}
                {...getSelectStyles()}
                isClearable
                placeholder="Select a Division"
                onBlur={onBlurWorkaround}
                options={divisions.map(formatDivisionOption)}
                isLoading={divisionsStatus === 'loading'}
              />
            )}
          />
          <ErrorMessage errors={form.formState.errors} name="division" render={renderErrorMessage} />
        </div>
      </FieldGroup>
      <FieldGroup title="Expectations">
        <Field label="Planned Cost" {...register('plannedCost')} min={0} type="number" errors={errors} requiredLabel={false} />
        <Field label="Expected Revenue" {...register('expectedRevenue')} min={0} type="number" errors={errors} requiredLabel={false} />
        <div>
          <Label htmlFor="expectedRevenue" label="Planned Start" />
          <DateField<NewCampaignFormData> control={form.control} name="plannedStart" rules={{ required: false }} />
        </div>
        <div>
          <Label htmlFor="plannedStart" label="Planned End" />
          <DateField<NewCampaignFormData> control={form.control} name="plannedEnd" rules={{ required: false }} />
        </div>
      </FieldGroup>
    </div>
  )
}
