'use client'

import React from 'react'
import { preventSheetClose } from 'src/lib/utils'

import { ButtonV2, Sheet, SheetContent, SheetTrigger } from '@fsg/gui-bits'

import { SheetProxyTrigger } from './'
import { CreateAccountWizard } from './Account'
import { CreateProps } from './constants'

// TODO >> See CreateLead for more TODOs

export function CreateAccount({ navMode, buttonActive }: CreateProps) {
  const [open, setOpen] = React.useState(false)

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {navMode ? (
        <SheetProxyTrigger buttonActive={buttonActive} />
      ) : (
        <ButtonV2 asChild className="font-bold" size="lg" variant="primary">
          <SheetTrigger>Create Account</SheetTrigger>
        </ButtonV2>
      )}
      <SheetContent
        onInteractOutside={(event) => {
          preventSheetClose(event)
        }}
        side="right"
        className="w-[600px] overflow-y-scroll"
      >
        <CreateAccountWizard setOpen={setOpen} />
      </SheetContent>
    </Sheet>
  )
}
