import React from 'react'

import { useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { useContactService } from '@app/hooks'
import { ContactResponse, ENTITY } from '@app/types'

type MatchProperties = {
  firstName: string
  lastName: string
  primaryPhone: string
  primaryEmail: string
}

export function useContactMatches() {
  const notification = useNotification()
  const service = useContactService()
  const [matches, setMatches] = React.useState<{
    exact: ContactResponse[]
    fuzzy: ContactResponse[]
  }>(undefined)
  const [matchesStatus, setMatchesStatus] = React.useState<Status>('idle')
  const [matchesError, setMatchesError] = React.useState<Error | null>(null)
  const [selectedMatch, setSelectedMatch] = React.useState<ContactResponse>()

  const getExactMatches = async (formData: MatchProperties) => {
    const { firstName, lastName } = formData

    // Note: primaryPhone.number is currently not working in the filter functionality
    const filterQueryString = `(firstName:eq(${firstName})&lastName:eq(${lastName}))|lastName:eq(${lastName})`

    try {
      const response = await service.getByParams({ filter: filterQueryString })
      if (response?.data?.length) {
        const matches = response.data.filter(
          (contact: ContactResponse) => contact.fullName.toLowerCase() === `${firstName} ${lastName}`.toLowerCase(),
        )
        return matches
      } else {
        return []
      }
    } catch (error: any) {
      notification.api.notify({
        message: `Error retrieving contact matches - ${error.message}`,
        type: 'warning',
      })
    }
  }

  const getFuzzyMatches = async (formData: MatchProperties) => {
    const { firstName, lastName } = formData

    const searchQueryString = `${firstName}+${lastName}`

    try {
      const response = await service.search(ENTITY.CONTACTS, searchQueryString)
      if (response?.data?.length) {
        const matches = response.data
        return matches
      } else {
        return []
      }
    } catch (error: any) {
      notification.api.notify({
        message: `Error retrieving contact matches - ${error.message}`,
        type: 'warning',
      })
    }
  }

  async function getMatches(formData: MatchProperties) {
    try {
      const [exact, fuzzy] = await Promise.all([getExactMatches(formData), getFuzzyMatches(formData)])
      // ? filter out the fuzzy match results that are already in the exact match list
      const idsInExactMatches = exact.map((obj) => obj.id)
      const filteredFuzzyMatches = fuzzy.filter((obj) => !idsInExactMatches.includes(obj.id))

      return { exact, fuzzy: filteredFuzzyMatches }
    } catch (error: any) {
      notification.api.notify({
        message: `Error retrieving contact matches - ${error.message}`,
        type: 'warning',
      })
    }
  }

  function reset() {
    setSelectedMatch(undefined)
    setMatches({ exact: [], fuzzy: [] })
  }

  return {
    matches,
    setMatches,
    matchesStatus,
    setMatchesStatus,
    matchesError,
    setMatchesError,
    getMatches,
    selectedMatch,
    setSelectedMatch,
    reset,
  }
}
