import React from 'react'
import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'

import { LoadingSpinner, useNotification, Wizard } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'
import { useRequestOnMount } from '@fsg/next-auth/useRequest'

import { useAppContextSelector } from '@app/components/AppContext'
import { ENDPOINTS } from '@app/constants'
import { useContactService } from '@app/hooks'
import { ContactRequest, ContactResponse, ContactType, NewContactFormData } from '@app/types'
import { formatCallingCodeOption, getPhoneRequest, routes } from '@app/utils'

import { CreateWizardBaseContextType } from '..'
import { MergeStep } from '../MergeStep'
import { getWizardConfig } from '../utils'
import { ContactMergeTable } from './ContactMergeTable'
import { CreateStep } from './CreateStep'
import { DuplicateContactCard } from './DuplicateContactCard'
import { DuplicatesSelectionStep } from './DuplicateSelectionStep'
import { transformMergedContactData } from './transformers'
import { useContactMatches } from './useContactMatches'

export interface ContactMergeFormData {
  firstName: string
  lastName: string
  isNewEmailSelected: boolean
  isNewPhoneSelected: boolean
  isNewAccountSelected: string
}

export type ContactWizardContext = CreateWizardBaseContextType<ContactResponse, NewContactFormData>

const { Step } = Wizard

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultValues?: Partial<NewContactFormData>
}

export function CreateContactWizard({ setOpen, defaultValues }: Props) {
  const notification = useNotification()
  const router = useRouter()
  const ContactsService = useContactService({ update: undefined })
  const DuplicatesService = useContactMatches()
  const { selectedMatch, setSelectedMatch } = DuplicatesService
  const countries = useAppContextSelector((state) => state.countries)
  const {
    data: contactTypes,
    status: contactTypesStatus,
    error: contactTypesError,
  } = useRequestOnMount<ContactType[]>({ path: ENDPOINTS.CONTACT_TYPES }, [])

  // should the form submission skip checking for duplicates
  const [shouldProceed, setShouldProceed] = React.useState(false)

  const [newContactStatus, setNewContactStatus] = React.useState<Status>('idle')

  const [contact, setContact] = React.useState<ContactResponse>()
  const [contactStatus, setContactStatus] = React.useState<Status>('idle')
  const [contactError, setContactError] = React.useState<Error | null>(null)

  const [updatedContact, setUpdatedContact] = React.useState<ContactResponse>()
  const [updatedContactStatus, setUpdatedContactStatus] = React.useState<Status>('idle')
  const [updatedContactError, setUpdatedContactError] = React.useState<Error | null>(null)

  const mergeForm = useForm<ContactMergeFormData>()

  // Note: Likely race conditions with countries not being available before the default values need to be set?
  // Consider creating a data-fetching wrapper around the wizard?
  const callingCodeOptionsMap = new Map(countries.map((country) => [country.alpha3code, formatCallingCodeOption(country)]))

  const createForm = useForm<NewContactFormData>({
    defaultValues: {
      callingCode: defaultValues?.callingCode ?? callingCodeOptionsMap.get('USA'),
      firstName: defaultValues?.firstName ?? '',
      lastName: defaultValues?.lastName ?? '',
      primaryEmail: defaultValues?.primaryEmail ?? '',
      primaryPhone: defaultValues?.primaryPhone ?? '',
      role: {
        accountId: defaultValues?.role?.accountId ?? null,
      },
    },
  })

  const contactTypesReady = contactTypes.length > 0 && contactTypesStatus === 'success'
  const countriesReady = countries.length > 0

  const dataIsReady = contactTypesReady && countriesReady

  if (!dataIsReady) return <LoadingSpinner />

  const config = getWizardConfig('Contact')

  const contextValue: ContactWizardContext = {
    ...config,
    duplicateMatches: DuplicatesService,
    shouldProceed,
    setShouldProceed,
    selectedMatch,
    setSelectedMatch,
    createForm,
  }

  const closeSheet = () => setOpen(false)

  function handleSuccessfulCreate(response: ContactResponse) {
    closeSheet()
    router.push(config.getSuccessPath(response.id))
  }

  function handleFailedCreate(error: any) {
    setNewContactStatus('error')
    notification.api.notify({
      message: `Error Creating ${config.entityType} - ${error.message}`,
      type: 'warning',
    })
  }

  async function createNewContact() {
    const data = createForm.getValues()

    const newPhoneData = {
      label: 'Phone',
      number: data.primaryPhone,
      callingCode: data.callingCode,
      isPrimary: true,
      extension: data.extension,
    }

    const contactTypesMap = new Map(contactTypes.map((c) => [c.description, c.id]))

    // Review: LeadConversionWizard is using NewContactRequest type --> should unify these in the service hook
    const requestBody: ContactRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      phones: [getPhoneRequest(newPhoneData)],
      emails: [{ address: data.primaryEmail, label: 'Email', isPrimary: true }],
      role: {
        accountId: data.role.accountId.value,
      },
      contactType: contactTypesMap.get('Customer'),
    }

    const response = await ContactsService.create(requestBody)
    return response
  }

  async function saveAsNew() {
    setNewContactStatus('loading')
    try {
      const res = await createNewContact()
      handleSuccessfulCreate(res)
    } catch (e) {
      handleFailedCreate(e)
    }
  }

  const selectContact = (contact: ContactResponse) => {
    setContact(undefined)
    setSelectedMatch(contact)
    setContactStatus('idle')
  }

  const renderContactMatch = (contact: ContactResponse) => {
    return <DuplicateContactCard contact={contact} selectedMatchId={selectedMatch?.id} onClickCard={selectContact} />
  }

  const getFullContact = async () => {
    setContactStatus('loading')
    try {
      const contact = await ContactsService.getById(selectedMatch.id)
      setContact(contact)
      setContactStatus('success')
      setContactError(null)
    } catch (error: any) {
      console.log(error.message)
      notification.api.notify({
        message: `Error Creating- ${error.message}`,
        type: 'warning',
      })
      setContactStatus('error')
    }
  }

  async function onSubmitMerge(mergeFormData: ContactMergeFormData) {
    setUpdatedContactStatus('loading')
    const createFormData = createForm.getValues()
    try {
      const requestBody = transformMergedContactData({ mergeFormData, createFormData, contact })
      const response = await ContactsService.updateById(requestBody, selectedMatch.id)
      closeSheet()
      router.push(routes.contact.basicInformation(response.id))
    } catch (error: any) {
      console.error(error)
      setUpdatedContactStatus('error')
      notification.api.notify({
        type: 'warning',
        message: `Failed to merge contact.\n\n${error.message}`,
      })
    }
  }

  const entityState = { data: contact, status: contactStatus, error: contactError }

  const updatedEntityState = { data: updatedContact, status: updatedContactStatus, error: updatedContactError }

  return (
    <Wizard contextValue={contextValue} className="h-full">
      <Step hideControls header={`Create ${config.entityType}`}>
        <CreateStep newEntityStatus={newContactStatus} setNewEntityStatus={setNewContactStatus} createNewEntity={saveAsNew} />
      </Step>
      <Step hideControls header={`Select ${config.entityType} Duplicate`}>
        <DuplicatesSelectionStep<ContactResponse>
          entityType={config.entityType}
          renderEntityMatch={renderContactMatch}
          setNewEntityStatus={setNewContactStatus}
          newEntityStatus={newContactStatus}
          matches={DuplicatesService.matches}
          saveAsNewEntity={saveAsNew}
        />
      </Step>
      <Step hideControls header={`Merge ${config.entityType}`}>
        <MergeStep
          successPath={config.getSuccessPath(updatedContact?.id)}
          getFullEntityPayload={getFullContact}
          entityType={config.entityType}
          entityState={entityState}
          updatedEntityState={updatedEntityState}
          onSubmit={mergeForm.handleSubmit(onSubmitMerge)}
          mergeForm={mergeForm}
          selectedMatch={selectedMatch}
        >
          <ContactMergeTable contact={contact} createFormData={createForm.watch()} />
        </MergeStep>
      </Step>
    </Wizard>
  )
}
