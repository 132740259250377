'use client'

import React from 'react'

import { createVariants } from '../constants'

export function FieldGroup({ children, title, description }: { children: React.ReactNode; title: string; description?: string }) {
  return (
    <>
      <div className="grid grid-cols-[2fr_3fr] gap-lg align-top">
        <div>
          <h2 className="text-md text-gray-darkest">{title}</h2>
          <p className="text-xs text-gray-darker">{description}</p>
        </div>
        <div className="[&>*+*]:pt-4">
          {React.Children.map(children, (child, index) => (
            <div className={createVariants.optgroup.base} key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <hr className="my-md h-px border-0 bg-border" />
    </>
  )
}
