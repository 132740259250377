import { routes } from 'src/lib/utils/routes'

type EntityType = 'Lead' | 'Account' | 'Contact' | 'Opportunity' | 'Site'

export function getWizardConfig(entityType: EntityType) {
  const getSuccessPath = (entityType: EntityType) => {
    switch (entityType) {
      case 'Lead':
        return (id: string) => routes.lead.general(id)
      case 'Account':
        return (id: string) => routes.account.basicInformation(id)
      case 'Opportunity':
        return (id: string) => routes.opportunity.basicInformation(id)
      case 'Contact':
        return (id: string) => routes.contact.basicInformation(id)
      case 'Site':
        return (id: string) => routes.site.general(id)
      default:
        throw new Error('Invalid entity type')
    }
  }

  return {
    entityType,
    sheetTriggerLabel: `Create ${entityType}`,
    gotoEntityLabel: `Go to ${entityType}`,
    getSuccessPath: getSuccessPath(entityType),
  }
}
