import { UseFormReturn } from 'react-hook-form'

import { Status } from '@fsg/next-auth/types'

export * from './CreateAccount'
export * from './CreateContact'
export * from './CreateLeadSheet'
export * from './CreateOpportunity'
export * from './CreateSiteSheet'
export * from './constants'
export * from './ui/Field'
export * from './ui/FieldGroup'
export * from './SheetErrorMessage'
export * from './SheetProxyTrigger'
export * from './MergeStep'
export * from './MergeTable'
export * from './CreateCampaignSheet'

export type DuplicateMatchesData<EntityResponseType> = {
  exact: EntityResponseType[]
  fuzzy: EntityResponseType[]
}

export interface CreateWizardBaseContextType<EntityResponseType, EntityFormDataType> {
  duplicateMatches?: {
    matches: DuplicateMatchesData<EntityResponseType>
    setMatches: React.Dispatch<React.SetStateAction<DuplicateMatchesData<EntityResponseType>>>
    matchesStatus: Status
    matchesError: Error | null
    getMatches: (formData: EntityFormDataType) => Promise<DuplicateMatchesData<EntityResponseType>>
  }
  shouldProceed: boolean
  setShouldProceed: React.Dispatch<React.SetStateAction<boolean>>
  selectedMatch?: EntityResponseType
  setSelectedMatch?: React.Dispatch<React.SetStateAction<EntityResponseType>>
  entityType: string
  sheetTriggerLabel: string
  gotoEntityLabel: string
  getSuccessPath: (id: string) => string
  createForm: UseFormReturn<EntityFormDataType>
}
