'use client'

import React from 'react'
import Link from 'next/link'
import debounce from 'just-debounce-it'
import { ControllerRenderProps, FieldValues, RegisterOptions } from 'react-hook-form'
import { GroupBase, Props as SelectFieldProps } from 'react-select'
import { routes } from 'src/lib/utils'

import { ExternalLink, OfficeBuilding } from '@fsg/icons'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { ENDPOINTS } from '@app/constants'
import { AccountOption, AccountResponse, ENTITY, Option } from '@app/types'

import { Typeahead } from '../Form'
import { formatAccountOptionLabel } from '../utilities/select/account/formatAccountOptionLabel'

interface Props extends SelectFieldProps<AccountOption, boolean, GroupBase<AccountOption>> {
  name: string
  placeholder?: string
  label: string
  readOnly?: boolean
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  isMulti?: boolean
}

export function AccountLookup(props: Props) {
  const { request } = useRequestCallback()
  const { name, placeholder, label, rules, isMulti, readOnly = false, ...selectProps } = props
  const fetchOptions = async (inputValue: string) => {
    const normalizedInput = inputValue.toLowerCase()
    const searchPath = `${ENDPOINTS.ENTITY_SEARCH(ENTITY.ACCOUNTS, normalizedInput)}&limit=5000`
    try {
      const response = await request<{ data: AccountResponse[] }>({
        path: searchPath,
        method: 'GET',
      })
      const options = response.data.map((account) => ({
        label: `${account.name}`,
        value: account.id,
        data: account,
      }))
      return options
    } catch (error) {
      console.error('Error fetching options:', error)
      return []
    }
  }

  const debouncedFetch = debounce((inputValue: string, resolve: (options: any[]) => void) => {
    fetchOptions(inputValue)
      .then(resolve)
      .catch((error: Error) => {
        console.error('Error in fetch:', error)
        resolve([])
      })
  }, 500)

  const loadOptions = (inputValue: string): Promise<any[]> => {
    return new Promise((resolve) => {
      debouncedFetch(inputValue, resolve)
    })
  }

  type DisplayProps = ControllerRenderProps<FieldValues, string>

  async function Display(field: DisplayProps) {
    try {
      if (!field.value.data) {
        const searchPath = `${ENDPOINTS.ACCOUNTS}/${field.value.value}`
        const account = await request<{ data: AccountResponse[] }>({
          path: searchPath,
          method: 'GET',
        })
        field.value.data = account
      }

      if (isMulti) {
        return (
          <div className={`${!readOnly ? 'py-md' : ''} text-sm font-regular`}>
            {field.value.map((option: AccountOption) => {
              const href = routes.account.basicInformation(option.value)
              return <AccountDisplay key={field.value} href={href} account={option.data} />
            })}
          </div>
        )
      } else {
        const { label, value: accountId, data: account } = field.value
        const href = routes.account.basicInformation(accountId)

        return (
          <div className={`${!readOnly ? 'py-xs' : ''} text-sm font-regular`}>
            <AccountDisplay account={account} href={href} />
          </div>
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  const AccountDisplay = ({ account, href, key }: { account: AccountResponse; href: string; key?: number | string }) => {
    const AccountName = () => <p className="text-sm font-semibold text-gray-900">{account?.name}</p>

    const AccountInfo = () => {
      const accountInfo = []
      if (account?.erpId) {
        accountInfo.push(account.erpId)
      }
      if (account?.primaryAddress) {
        accountInfo.push(`${account.primaryAddress.line1}, ${account.primaryAddress.city}, ${account.primaryAddress.state}`)
      }
      if (account?.apContact) {
        accountInfo.push(account.apContact.fullName)
      }
      if (account?.primaryPhone) {
        accountInfo.push(account.primaryPhone.number)
      }

      return <p className="text-xs text-gray-900">{accountInfo.join(' · ')}</p>
    }

    return (
      <Link href={href} key={key}>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-xs">
            <div>
              <OfficeBuilding className="option-icon h-[28px] w-[28px] rounded-[50%] bg-red-100 p-[0.5rem] text-red-800" />
            </div>
            <div className="flex flex-col">
              <AccountName />
              <AccountInfo />
            </div>
          </div>
          <ExternalLink className="inline" />
        </div>
      </Link>
    )
  }

  const renderDisplayComponent = (displayProps: DisplayProps) => <Display {...displayProps} />

  return (
    <Typeahead
      noOptionsMessage={() => `Start typing to select ${label}`}
      rules={rules}
      isMulti={isMulti ?? false}
      name={name}
      label={label}
      placeholder={placeholder}
      loadOptions={loadOptions}
      renderDisplayComponent={renderDisplayComponent}
      formatOptionLabel={formatAccountOptionLabel}
      readOnly={readOnly}
      {...selectProps}
    />
  )
}
