'use client'

import React from 'react'
import { preventSheetClose } from 'src/lib/utils'

import { ButtonV2, Sheet, SheetContent, SheetTrigger } from '@fsg/gui-bits'

import { SheetProxyTrigger } from '.'
import { CreateProps } from './constants'
import { CreateContactWizard } from './Contact/CreateContactWizard'
import { NewContactFormData } from 'types/contacts'

interface Props extends CreateProps {
  defaultValues?: Partial<NewContactFormData>
}

export function CreateContact({ navMode, buttonActive, defaultValues }: Props) {
  // is Sheet open?
  const [open, setOpen] = React.useState(false)

  // * Edit for each entity type here...
  const entityType = 'Contact' // * FYI this is used in Link too
  const sheetTriggerLabel = `Create ${entityType}`

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {navMode ? (
        <SheetProxyTrigger buttonActive={buttonActive} />
      ) : (
        <ButtonV2 asChild className="font-bold" size="lg" variant="primary">
          <SheetTrigger>{sheetTriggerLabel}</SheetTrigger>
        </ButtonV2>
      )}
      <SheetContent
        side="right"
        className="w-[600px]"
        onInteractOutside={(event) => {
          preventSheetClose(event)
        }}
      >
        <CreateContactWizard setOpen={setOpen} defaultValues={defaultValues} />
      </SheetContent>
    </Sheet>
  )
}
