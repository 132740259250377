import React from 'react'

import { useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { useAccountService } from '@app/hooks'
import { AccountResponse, ENTITY } from '@app/types'

type MatchProperties = {
  name: string
  phone: string
}

export function useAccountMatches() {
  const service = useAccountService()
  const notification = useNotification()
  const [matches, setMatches] = React.useState<{
    exact: AccountResponse[]
    fuzzy: AccountResponse[]
  }>(undefined)
  const [matchesStatus, setMatchesStatus] = React.useState<Status>('idle')
  const [matchesError, setMatchesError] = React.useState<Error | null>(null)
  const [selectedMatch, setSelectedMatch] = React.useState<AccountResponse>()

  const getExactMatches = async (formData: MatchProperties) => {
    const { name, phone } = formData

    // Note: currently phone is not part of accounts search queries but should be added in the future
    const filterQueryString = `name:eq(${name})`

    try {
      const response = await service.getByParams({ filter: filterQueryString })
      if (response?.data?.length) {
        const matches = response.data
        return matches
      } else {
        return []
      }
    } catch (error: any) {
      notification.api.notify({
        message: `Error retrieving lead matches - ${error.message}`,
        type: 'warning',
      })
    }
  }

  // Note: for match criteria, check https://linear.app/fsgsb/issue/FSG-6266/define-create-entity-duplicate-search-criteria
  const getFuzzyMatches = async (formData: MatchProperties) => {
    const { name } = formData

    const searchQueryString = name

    try {
      const response = await service.search(ENTITY.ACCOUNTS, searchQueryString)

      if (response?.data?.length) {
        const matches = response.data
        return matches
      } else {
        return []
      }
    } catch (error: any) {
      notification.api.notify({
        message: `Error retrieving contact matches - ${error.message}`,
        type: 'warning',
      })
    }
  }

  async function getMatches(formData: MatchProperties) {
    try {
      const [exact, fuzzy] = await Promise.all([getExactMatches(formData), getFuzzyMatches(formData)])
      // ? filter out the fuzzy match results that are already in the exact match list
      const idsInExactMatches = exact.map((obj) => obj.id)
      const filteredFuzzyMatches = fuzzy.filter((obj) => !idsInExactMatches.includes(obj.id))
      return { exact, fuzzy: filteredFuzzyMatches }
    } catch (error: any) {
      notification.api.notify({
        message: `Error retrieving contact matches - ${error.message}`,
        type: 'warning',
      })
    }
  }

  function reset() {
    setSelectedMatch(undefined)
    setMatches({ exact: [], fuzzy: [] })
  }

  return {
    matches,
    setMatches,
    matchesStatus,
    setMatchesStatus,
    matchesError,
    setMatchesError,
    getMatches,
    selectedMatch,
    setSelectedMatch,
    reset,
  }
}
