import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import debounce from 'just-debounce-it'
import { Controller, UseFormReturn } from 'react-hook-form'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { useAppContextSelector } from 'src/app-components/AppContext'
import { CONTACT_TYPES } from 'src/lib/constants/contactTypes'
import { formatContactOption, getSelectStyles, onBlurWorkaround, renderErrorMessage } from 'src/lib/utils'

import { useRequestCallback } from '@fsg/next-auth/useRequest'
import { pattern } from '@fsg/utils'

import { ContactsQuery } from '@app/api/EntityQuery'
import { FilterQueryParams } from '@app/api/FilterQueryParams'
import { EmployeeContactLookup } from '@app/components/Lookups/EmployeeContactLookup'
import { ENDPOINTS, VALIDATIONS } from '@app/constants'
import { ContactOption, ContactResponse } from '@app/types'

import { Field } from '../ui/Field'
import { FieldGroup } from '../ui/FieldGroup'
import { NewLeadFormData } from './CreateLead'
import { DivisionSelectField } from '@app/components/Form/DivisionSelectField'

type Props = {
  form: UseFormReturn<NewLeadFormData>
}

export function NewLeadForm({ form }: Props) {
  const { request } = useRequestCallback()
  const countries = useAppContextSelector((state) => state.countries)
  const {
    register,
    formState: { errors },
    watch,
  } = form

  async function fetchEmployees(inputValue: string): Promise<ContactOption[]> {
    try {
      const query = new ContactsQuery()
      const fq = new FilterQueryParams()
      const isEmployee = fq.textFilter({ field: 'contactType.code', operation: 'equals', value: CONTACT_TYPES.EMPLOYEE })
      const hasFSGEmployeeId = fq.textFilter({ field: 'fsgEmployeeId', operation: 'notBlank' })
      const normalizedInput = inputValue.toLowerCase()

      query.search(normalizedInput)
      query.filter(fq.and(isEmployee, hasFSGEmployeeId))

      const response = await request<{ data: ContactResponse[] }>({
        path: query.getPath(),
        method: 'GET',
      })
      const options = response.data.map(formatContactOption)

      return options
    } catch (error) {
      console.error('Error fetching options:', error)
      return []
    }
  }

  const debouncedFetch = debounce((inputValue: string, resolve: (options: any[]) => void) => {
    fetchEmployees(inputValue)
      .then(resolve)
      .catch((error: Error) => {
        console.error('Error in debounced fetch:', error)
        resolve([])
      })
  }, 500)

  const loadOptions = (inputValue: string): Promise<any[]> => {
    return new Promise((resolve) => {
      debouncedFetch(inputValue, resolve)
    })
  }

  const callingCodeOptions = countries.map((country) => ({
    label: `+${country.telephoneCode}`,
    value: country,
  }))

  const hasName = watch('firstName').trim().length > 0 && watch('lastName').trim().length > 0
  const hasCompany = watch('companyName').trim().length > 0

  return (
    <div className="grid">
      <FieldGroup title="Lead Name" description="What name should FSG use to reference this lead in official communication.">
        <Field
          requiredLabel={!hasCompany}
          label="First Name"
          {...register('firstName', {
            validate: (value) => {
              if (!value) {
                if (!hasCompany) {
                  return 'You must provide a first & last name or company name.'
                }
              }
            },
          })}
          errors={errors}
        />
        <Field
          requiredLabel={!hasCompany}
          label="Last Name"
          {...register('lastName', {
            validate: (value) => {
              if (!value) {
                if (!hasCompany) {
                  return 'You must provide a first & last name or company name.'
                }
              }
            },
          })}
          errors={errors}
        />
      </FieldGroup>
      <FieldGroup title="Owner" description="Please specify the main FSG sales representative assigned to this lead.">
        <EmployeeContactLookup name="primaryRep" label="Owner" rules={{ required: VALIDATIONS.REQUIRED }} />
        <DivisionSelectField
            label="Division"
            name="division"
            rules={{ required: VALIDATIONS.REQUIRED }}
            placeholder="No division provided"
            isClearable={false}
          />
      </FieldGroup>
      <FieldGroup title="Company Information" description="Please provide any company information available.">
        <Field
          requiredLabel={!hasName}
          label="Company Name"
          {...register('companyName', {
            validate: (value) => {
              if (!value) {
                if (!hasName) {
                  return 'You must provide a first & last name or company name.'
                }
              }
            },
          })}
          errors={errors}
        />
        <Field requiredLabel={false} label="Title" {...register('title')} errors={errors} />
      </FieldGroup>
      <FieldGroup title="Phone Number" description="Please provide a phone number for this lead, if available.">
        <label className="text-sm text-gray-darker">Calling Code</label>
        <Controller
          name="callingCode"
          control={form.control}
          render={({ field }) => (
            <Select {...field} options={callingCodeOptions} isClearable={false} {...getSelectStyles()} onBlur={onBlurWorkaround} />
          )}
        />
        <ErrorMessage name="callingCode" errors={errors} render={renderErrorMessage} />
        <Field requiredLabel={false} label="Phone Number" {...register('phone', { pattern: VALIDATIONS.PHONE_PATTERN })} errors={errors} />
        <Field requiredLabel={false} label="Extension" {...register('extension')} errors={errors} />
      </FieldGroup>
      <FieldGroup title="Primary Email Address" description="Please provide the primary email address for this lead, if available.">
        <Field requiredLabel={false} label="Email Address" {...register('primaryEmail', { pattern: pattern.email })} errors={form.formState.errors} />
      </FieldGroup>
      <FieldGroup title="Notes" description="Enter any additional detail about the lead.">
        <Controller
          name="notes"
          control={form.control}
          render={({ field }) => (
            <div className="inline-flex rounded-md border border-solid border-gray px-4 py-4 text-sm text-true-black font-regular ">
              <textarea className="w-full focus:outline-none" rows={5} {...field} value={field.value || ''} />
            </div>
          )}
        />
      </FieldGroup>
    </div>
  )
}
