'use client'

import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import { useSession } from 'next-auth/react'
import { FieldErrors, useForm, UseFormReturn } from 'react-hook-form'

import { ButtonV2, Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger, useNotification } from '@fsg/gui-bits'

import { useOpportunityService } from '@app/hooks'
import { NewOpportunityFormData, OpportunityRequest, OpportunityResponse } from '@app/types'
import { formatContactOption, formatDivisionOption, preventSheetClose } from '@app/utils'

import { useAppContextSelector } from '../AppContext'
import { Form } from '../Form'
import { SheetErrorMessage, SheetProxyTrigger } from './'
import { CreateProps, CreateStatus } from './constants'
import { NewOpportunityForm } from './CreateOpportunity/NewOpportunityForm'

function transformCreate(data: NewOpportunityFormData) {
  // TODO: Refactor by adding NewOpportunityRequest to types and add to the service hook generic, then send only the form data on submit and pass a `create` transformer to the service hook
  // TODO: Keep in mind conventions with sending lookup fields as a string in POST
  const transformedData: OpportunityRequest = {
    name: data.name,
    accountPortfolioManagerId: data.accountPortfolioManagerId.value,
    accountId: data.accountId.value,
    primaryStakeholderId: data.primaryStakeholderId.value,
    division: data.division.value,
    salesType: data.salesType.value,
    stage: 'SCOPE_DEVELOPMENT',
    industries: [],
    campaignId: data.campaignId,
    source: data.source,
  }

  return transformedData
}

interface Props extends CreateProps {
  defaultValues?: Partial<NewOpportunityFormData>
}

export function CreateOpportunity({ navMode, buttonActive, defaultValues }: Props) {
  const [open, setOpen] = React.useState(false)

  const entityType = 'Opportunity'
  const sheetTitle = `Create ${entityType}`
  const sheetTriggerLabel = `Create ${entityType}`
  const router = useRouter()
  const notification = useNotification()
  const service = useOpportunityService()
  const [status, setStatus] = React.useState<CreateStatus>('idle')
  const closeSheet = () => setOpen(false)

  const form = useForm<NewOpportunityFormData>({
    defaultValues: {
      name: defaultValues?.name || '',
      accountPortfolioManagerId: defaultValues?.accountPortfolioManagerId || null,
      accountId: defaultValues?.accountId || null,
      primaryStakeholderId: defaultValues?.primaryStakeholderId || null,
      salesType: defaultValues?.salesType || null,
      division: defaultValues?.division || null,
      campaignId: defaultValues?.campaignId || null,
      source: defaultValues?.source ?? null,
    },
  })

  function handleSuccessfulCreate(response: OpportunityResponse) {
    // setResponse(response)
    setStatus('success')
    form.reset()
    closeSheet()
    router.push(`/opportunities/${response.id}/general/basic-information`)
  }

  function handleFailedCreate(e: Error) {
    // setResponse(undefined)
    setStatus('error')
    notification.api.notify({
      message: `Error Creating ${entityType} - ${e.message}`,
      type: 'warning',
    })
  }

  async function onSubmit(data: any) {
    setStatus('pending')
    const requestData = transformCreate(data)
    try {
      // Note: should eventually handle case where userContact is not found AKA the logged in user doesn't have a Contact profile associated with them/their email
      const response = await service.create(requestData)
      handleSuccessfulCreate(response)
    } catch (e: any) {
      handleFailedCreate(e)
    }
  }

  function submitErrorHandler(errors: FieldErrors) {
    notification.api.notify({
      message: 'Some fields are missing or incorrect',
      type: 'warning',
    })
    console.log(errors)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {navMode ? (
        <SheetProxyTrigger buttonActive={buttonActive} />
      ) : (
        <ButtonV2 asChild className="font-bold" size="lg" variant="primary">
          <SheetTrigger>{sheetTriggerLabel}</SheetTrigger>
        </ButtonV2>
      )}
      <SheetContent
        onInteractOutside={(event) => {
          preventSheetClose(event)
        }}
        side="right"
        className="w-[600px]"
      >
        <SheetHeader className="pb-4">
          <SheetTitle className="text-xl">{sheetTitle}</SheetTitle>
        </SheetHeader>
        <CreateOpportunitySheet
          entityType={entityType}
          form={form}
          onSubmit={onSubmit}
          submitErrorHandler={submitErrorHandler}
          status={status}
          defaultValues={defaultValues}
        />
      </SheetContent>
    </Sheet>
  )
}

type SheetProps = {
  entityType?: string
  defaultValues?: Partial<NewOpportunityFormData>
  form: UseFormReturn<any>
  status: string
  onSubmit: (data: any) => any
  submitErrorHandler?: (data: any) => any
  controls?: JSX.Element
}

export function CreateOpportunitySheet({
  entityType = 'Opportunity',
  defaultValues,
  form,
  status,
  onSubmit,
  submitErrorHandler = null,
  controls,
}: SheetProps) {
  const saveButtonLabel = `Save as New ${entityType}`

  const errorMessage = `There was an error creating the ${entityType}`

  const { data: sessionData } = useSession()
  const userContact = useAppContextSelector((state) => state.userContact)
  const { data: divisions, status: divisionsStatus } = useAppContextSelector((state) => state.divisions)
  const { user } = sessionData
  const [hasSetDefaults, setHasSetDefaults] = useState(false)

  useEffect(() => {
    const dataIsReady = divisionsStatus === 'success' && userContact
    if (dataIsReady && !hasSetDefaults) {
      // when default values arrive asynchronously, use reset to pass defaultValues to the hook form
      // in this case, the division and accountPortfolioManagerId values are dependent on the userContact data, which will arrive asynchronously

      const defaultApmOption = userContact?.id ? formatContactOption(userContact) : null

      // a little confusing because `divisionNumber` is not a number, but rather a string
      const division = divisions.find(({ number: divisionNumber }) => divisionNumber == user?.division.toString())
      const divisionOption = division !== undefined ? formatDivisionOption(division) : null

      form.reset({
        ...defaultValues,
        accountPortfolioManagerId: defaultValues?.accountPortfolioManagerId ?? defaultApmOption,
        division: defaultValues?.division ?? divisionOption,
      })

      setHasSetDefaults(true)
    }
  }, [userContact, hasSetDefaults, form, user?.division, defaultValues, divisions, divisionsStatus])

  return (
    <Form form={form} isEditing onSubmit={form.handleSubmit(onSubmit, submitErrorHandler)}>
      <NewOpportunityForm form={form} />

      {status === 'error' ? <SheetErrorMessage msg={errorMessage}></SheetErrorMessage> : null}

      <SheetFooter className="flex justify-end gap-sm py-12">
        {controls ? (
          controls
        ) : (
          <>
            <ButtonV2 size="lg" type="button" onClick={() => form.reset()}>
              Reset
            </ButtonV2>
            <ButtonV2 size="lg" type="submit" variant="primary" className="font-bold" disabled={status === 'pending'}>
              {saveButtonLabel}
            </ButtonV2>
          </>
        )}
      </SheetFooter>
    </Form>
  )
}
