import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ContactResponse, NewContactFormData } from '@app/types'

import { DataCell, DataColumn, DataRow, MergeTable, SectionHeaderRow, SubHeaderRow } from '../MergeTable'
import { ContactMergeFormData } from './CreateContactWizard'
import { getPhoneDisplayString } from '@app/utils'
import { checkIfAccountIsDuplicate, checkIfEmailIsDuplicate, checkIfPhoneIsDuplicate } from '../utils'

type Props = {
  contact: ContactResponse
  createFormData: NewContactFormData
}

export function ContactMergeTable({ contact, createFormData }: Props) {
  const mergeForm = useFormContext<ContactMergeFormData>()

  const phoneData = {
    number: createFormData.primaryPhone,
    callingCode: createFormData.callingCode,
    extension: createFormData.extension,
  }

  const newEmailIsDuplicate = checkIfEmailIsDuplicate(createFormData.primaryEmail, contact.emails)

  const newPhoneIsDuplicate = checkIfPhoneIsDuplicate(phoneData, contact.phones)

  const newAccountIsDuplicate = checkIfAccountIsDuplicate(createFormData.role.accountId.value, contact.currentRole)

  const createFormPhoneNumber = getPhoneDisplayString(phoneData)

  return (
    <MergeTable>
      <SectionHeaderRow>
        Select <strong>one</strong> of each field below
      </SectionHeaderRow>
      <SubHeaderRow />
      <DataRow label="First Name">
        <DataCell>
          <input type="radio" {...mergeForm.register('firstName')} id="createFormData.firstName" value={createFormData.firstName} />
          <label htmlFor="createFormData.firstName">{createFormData.firstName}</label>
        </DataCell>
        <DataCell>
          <input type="radio" {...mergeForm.register('firstName')} id="contact.firstName" value={contact.firstName} defaultChecked={true} />
          <label htmlFor="contact.firstName">{contact.firstName}</label>
        </DataCell>
      </DataRow>
      <DataRow label="Last Name">
        <DataCell>
          <input {...mergeForm.register('lastName')} type="radio" id="createFormData.lastName" value={createFormData.lastName} />
          <label htmlFor="createFormData.lastName">{createFormData.lastName}</label>
        </DataCell>
        <DataCell>
          <input type="radio" {...mergeForm.register('lastName')} id="contact.lastName" value={contact.lastName} defaultChecked={true} />
          <label htmlFor="contact.lastName">{contact.lastName}</label>
        </DataCell>
      </DataRow>

      <SectionHeaderRow>
        Select <strong>all</strong> information below you would like to keep
      </SectionHeaderRow>
      <SubHeaderRow />
      <DataRow label="Account">
        <DataCell>
          <input
            {...mergeForm.register('isNewAccountSelected')}
            type="checkbox"
            id="createFormData.role.accountId"
            defaultChecked={!newAccountIsDuplicate && true}
            disabled={newAccountIsDuplicate}
          />
          <label htmlFor="createFormData.role.accountId">{createFormData.role.accountId.label}</label>
        </DataCell>
        {contact.currentRole?.accountName ? (
          <DataCell>
            <p>{contact.currentRole?.accountName}</p>
          </DataCell>
        ) : null}
      </DataRow>
      <DataRow label="Phone Number">
        <DataColumn>
          <DataCell>
            <input
              type="checkbox"
              {...mergeForm.register('isNewPhoneSelected')}
              id="createFormData.primaryPhone"
              defaultChecked={!newPhoneIsDuplicate && true}
              disabled={newPhoneIsDuplicate}
            />
            <label htmlFor="createFormData.primaryPhone">{createFormPhoneNumber}</label>
          </DataCell>
        </DataColumn>
        <DataColumn>
          {contact.phones?.map((phone) => (
            <DataCell key={phone.id}>
              <p>{phone.number}</p>
            </DataCell>
          ))}
        </DataColumn>
      </DataRow>
      <DataRow label="Email Address">
        <DataColumn>
          <DataCell>
            <input
              type="checkbox"
              {...mergeForm.register('isNewEmailSelected')}
              id="createFormData.primaryEmail"
              defaultChecked={!newEmailIsDuplicate && true}
              disabled={newEmailIsDuplicate}
            />
            <label htmlFor="createFormData.primaryEmail">{createFormData.primaryEmail}</label>
          </DataCell>
        </DataColumn>
        <DataColumn>
          {contact.emails?.map((email) => (
            <DataCell key={email.id}>
              <p>{email.address}</p>
            </DataCell>
          ))}
        </DataColumn>
      </DataRow>
    </MergeTable>
  )
}
