import { OfficeBuilding } from '@fsg/icons'

import { formatAccountOption } from '@app/utils/formUtilities'

import '../optionLabelStyles.scss'

import { Option } from '@app/types'

type DisplayProps = {
  option: ReturnType<typeof formatAccountOption>
  selected?: boolean
}

const AccountName = ({ option }: DisplayProps) => <p className="text-sm font-semibold text-gray-900">{option.label}</p>

const AccountInfo = ({ option }: DisplayProps) => {
  const accountInfo = []
  if (option.data?.erpId) {
    accountInfo.push(option.data.erpId)
  }
  if (option.data?.primaryAddress) {
    accountInfo.push(`${option.data?.primaryAddress.line1}, ${option.data?.primaryAddress.city}, ${option.data?.primaryAddress.state}`)
  }
  if (option.data?.apContact) {
    accountInfo.push(option.data.apContact.fullName)
  }
  if (option.data?.primaryPhone) {
    accountInfo.push(option.data.primaryPhone.number)
  }

  return <p className="text-xs text-gray-900">{accountInfo.join(' · ')}</p>
}

export function formatAccountOptionLabel(x: any, OptionLabelMeta: any, multi: boolean = false) {
  const option = x as ReturnType<typeof formatAccountOption>
  const { context, selectValue } = OptionLabelMeta || { context: 'response' }
  const selected = selectValue.find((x: Option) => x.value === option.value)
  if (context === 'menu') {
    return (
      <div className="flex items-center gap-xl">
        <div>
          <OfficeBuilding
            className={`rounded-[50%] ${
              selected ? 'bg-blue-100 text-blue-700' : 'bg-red-100 text-red-800'
            } option-icon h-[28px] w-[28px] p-[0.5rem] `}
          />
        </div>
        <div>
          <AccountName option={option} selected={selected} />
          <AccountInfo option={option} selected={selected} />
        </div>
      </div>
    )
  } else {
    return (
      option.label.trim().length > 0 && (
        <div>
          <AccountName option={option} selected={selected} />
          {!multi && <AccountInfo option={option} selected={selected} />}
        </div>
      )
    )
  }
}
