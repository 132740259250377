import React, { PropsWithChildren } from 'react'

import { ButtonV2, SheetFooter, SheetHeader, SheetTitle, useWizardContext } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

type Props = {
  entityType: string
  loading: boolean
  disabled: boolean
  status: Status
  onSubmit: (event: React.BaseSyntheticEvent<object, any, any>) => void
  successPath: string
}

export function MergeStepDisplay(props: PropsWithChildren<Props>) {
  const { children, entityType, loading, disabled, onSubmit } = props

  const { prevStep } = useWizardContext()

  return (
    <form className="h-full flex flex-col" onSubmit={onSubmit}>
      <em className="mb-lg text-sm text-gray-darker">Select the information you would like to include in the merged {entityType.toLowerCase()}.</em>
      {children}
      <SheetFooter className="flex items-center justify-between sm:justify-between mt-auto gap-sm py-12">
        <ButtonV2 variant="secondary" size="md" onClick={prevStep}>
          Previous
        </ButtonV2>
        <ButtonV2 variant="primary" size="md" type="submit" loading={loading} disabled={disabled}>
          Finish
        </ButtonV2>
      </SheetFooter>
    </form>
  )
}
