import React, { useCallback, useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
// import { AddressAutofillRetrieveResponse } from '@mapbox/search-js-core'
// import { AddressAutofill, config } from '@mapbox/search-js-react'
import debounce from 'just-debounce-it'
import { Controller, UseFormReturn } from 'react-hook-form'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { useAppContextSelector } from 'src/app-components/AppContext'
import { usePageContext } from 'src/app-components/PageContextProvider'
import { AccountContextValueType } from 'src/app/accounts/[accountId]/AccountPageContextProvider'
import { AccountResponse } from 'types/accounts'

import { useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { ENDPOINTS, VALIDATIONS } from '@app/constants'
import { CountryType, Option, SelectOption, SiteResponse, SiteTypes, StatesResponseType, StateType } from '@app/types'
import {
  formatCallingCodeOption,
  formatCountryOption,
  formatOptionLabel,
  formatSiteTypeOption,
  getSelectStyles,
  onBlurWorkaround,
  renderErrorMessage,
  siteTypeFilterOptions,
  sortCountries,
} from '@app/utils'

import { createVariants } from '../constants'
import { Field } from '../ui/Field'
import { FieldGroup } from '../ui/FieldGroup'
import { NewSiteFormData } from './CreateSite'
import { SiteNameGeneratorProps, useSiteNameGenerator } from './useSiteNameGenerator'

type Props = {
  form: UseFormReturn<NewSiteFormData>
  nameGenerator: SiteNameGeneratorProps
}

export function NewSiteForm({ form, nameGenerator: siteGenerator }: Props) {
  const notification = useNotification()
  const { request } = useRequestCallback()
  const { account } = usePageContext<AccountContextValueType>()
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form

  const [siteTypes, setSiteTypes] = useState<SiteTypes[]>([])
  const countries = useAppContextSelector((state) => state.countries)
  const [states, setStates] = useState<StateType[]>([])
  const [statesStatus, setStatesStatus] = useState<Status>('idle')
  const [statesError, setStatesError] = useState<Error | null>(null)
  const name = watch('name')
  const siteId = watch('customerSiteId')
  // const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN
  // config.accessToken = MAPBOX_TOKEN

  // const Autofill = AddressAutofill as any
  // const handleAutoFill = async (feature: AddressAutofillRetrieveResponse) => {
  //   if (feature.features.length) {
  //     const address = feature.features[0].properties
  //     if (!address.address_line2) {
  //       setValue('address.line2', '')
  //     }

  //     const country = countries.find((country) => country.alpha2code === address.country_code.toUpperCase())
  //     const states = await getStates(country.name)
  //     setStates(states)

  //     if (country) setValue('address.country', { label: country.name, value: country })
  //     else setValue('address.country', null)

  //     const state = states.find((state) => state.code === address.address_level1 || state.name === address.address_level1)

  //     if (state) setValue('address.state', { label: state.name, value: state.name })
  //     else setValue('address.state', null)
  //   }
  // }

  const formatStateOption = (state: StateType) => ({ label: state.name, value: state.name })

  const getStates = useCallback(
    async (countryCode: string) => {
      const res = await request<StatesResponseType>({ path: ENDPOINTS.GEO.STATES(countryCode || 'US'), method: 'GET' })
      return res
    },
    [request],
  )

  const callingCodeOptionsMap = new Map(countries.map((country) => [country.alpha3code, formatCallingCodeOption(country)]))

  const callingCodeOptions = Array.from(callingCodeOptionsMap.values())

  const handleGetStatesSuccess = useCallback((states: StatesResponseType) => {
    setStates(states)
    setStatesStatus('success')
    setStatesError(null)
  }, [])

  const handleGetStatesFail = useCallback((error: Error) => {
    setStates([])
    setStatesStatus('error')
    setStatesError(error as Error)
  }, [])

  const getSiteTypes = useCallback(async () => {
    try {
      const types = await request<SiteTypes[]>({ path: ENDPOINTS.SITE_TYPES })
      return types
    } catch (error) {
      console.error('Error fetching options:', error)
    }
  }, [request])

  useEffect(() => {
    // ? Don't fetch anything if the sheet is closed
    // Note: maybe this behavior is not necessary, just trying to debug potential inf loop
    if (!open) return
    let ignore = false
    async function getDefaultData() {
      try {
        const [states, types] = await Promise.all([getStates('US'), getSiteTypes()])

        if (ignore) return

        handleGetStatesSuccess(states)
        setSiteTypes(types)
      } catch (error) {
        handleGetStatesFail(error as Error)
        setSiteTypes([])
        console.error('Error getting default data')
        notification.api.notify({
          message: 'Error getting default data',
          type: 'warning',
        })
      }
    }
    getDefaultData()

    return () => {
      ignore = true
    }
  }, [getStates, getSiteTypes, handleGetStatesSuccess, handleGetStatesFail, setSiteTypes, notification.api])

  async function fetchAccounts(inputValue: string) {
    const normalizedInput = inputValue.toLowerCase()
    try {
      const response = await request<{ data: AccountResponse[] }>({
        path: `${ENDPOINTS.ACCOUNTS}?search=${normalizedInput}`,
        method: 'GET',
      })
      const options = response.data.map((account) => ({
        label: `${account.name}`,
        value: account.id,
      }))
      return options
    } catch (error) {
      console.error('Error fetching options:', error)
      return []
    }
  }

  const debouncedFetch = debounce((inputValue: string, resolve: (options: any[]) => void) => {
    fetchAccounts(inputValue)
      .then(resolve)
      .catch((error: Error) => {
        console.error('Error in debounced fetch:', error)
        resolve([])
      })
  }, 500)

  const loadOptions = (inputValue: string): Promise<any[]> => {
    return new Promise((resolve) => {
      debouncedFetch(inputValue, resolve)
    })
  }

  useEffect(() => {
    const newName = siteGenerator.generateName()
    setValue('name', newName)
  }, [siteId])

  useEffect(() => {
    if (name?.trim().length === 0) {
      const newName = siteGenerator.generateName()
      setValue('name', newName)
    }
  })

  const autofillStyle = { display: 'flex', flexDirection: 'column' }
  return (
    <div className="grid">
      <FieldGroup title="Site Owner" description="The owner of this Site.">
        <AsyncSelect
          isDisabled
          defaultValue={getValues('ownerId')}
          {...register('ownerId', { required: VALIDATIONS.REQUIRED })}
          onChange={(newValue, _) => {
            setValue('ownerId', newValue as unknown as Option)
          }}
          isClearable
          placeholder="Start typing to search..."
          loadOptions={loadOptions}
          onBlur={onBlurWorkaround}
        />
        <Field label="Customer's Store Number" requiredLabel={false} name="customerSiteId" {...register('customerSiteId')} errors={errors} />
        <Field label="Site Name" name="name" {...register('name', { required: VALIDATIONS.REQUIRED })} errors={errors} />
        {VALIDATIONS.styleLabel('Site Type')}
        <Select
          {...register('siteType', { required: VALIDATIONS.REQUIRED })}
          name="siteType"
          options={siteTypes.map((type) => formatSiteTypeOption(type))}
          onChange={(newValue: any) => setValue('siteType', newValue)}
          styles={{
            control: (base: any) => ({
              ...base,
              borderColor: '#e0e1e2',
              borderRadius: '0.8rem',
              padding: '2px 3px 3px 3px',
              minWidth: '165px',
            }),
            input: (base: any) => ({ ...base, fontSize: '14px' }),
            option: (base: any) => ({ ...base, fontSize: '14px' }),
            singleValue: (base: any) => ({ ...base, fontSize: '14px' }),
            multiValue: (base: any) => ({ ...base, fontSize: '14px' }),
            placeholder: (base: any) => ({ ...base, fontSize: '14px' }),
          }}
          formatOptionLabel={(option: any, FormatOptionLabelMeta: any) => formatOptionLabel(option, FormatOptionLabelMeta)}
          filterOption={(option: any, input): any => {
            return siteTypeFilterOptions(option, input)
          }}
          onBlur={onBlurWorkaround}
        />
      </FieldGroup>
      <FieldGroup title="Site Address" description="Provide a valid address for this Site.">
        {/* <Autofill
          style={autofillStyle}
          accessToken={MAPBOX_TOKEN}
          onRetrieve={handleAutoFill}
          theme={{
            variables: {
              fontFamily: 'sans-serif',
            },
          }}
        > */}
        <div className="flex flex-col">
          <Field
            label="Street Address"
            {...register('address.line1', { required: VALIDATIONS.REQUIRED })}
            errors={errors}
            autoComplete="address-line1"
          />
        </div>
        {/* </Autofill> */}
        <Field requiredLabel={false} label="Line 2" {...register('address.line2')} errors={errors} autoComplete="address-line2" />
        <Field label="City" {...register('address.city', { required: VALIDATIONS.REQUIRED })} errors={errors} autoComplete="address-level2" />
        <div className="grid grid-cols-2 gap-xl">
          <div className={createVariants.optgroup.base}>
            <label htmlFor="state" className={createVariants.label.base}>
              State
            </label>
            <Controller
              name="address.state"
              control={form.control}
              rules={{ required: VALIDATIONS.REQUIRED }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={states.map(formatStateOption)}
                  isLoading={statesStatus === 'loading'}
                  {...getSelectStyles()}
                  onBlur={onBlurWorkaround}
                />
              )}
            />
          </div>
          <div className={createVariants.optgroup.base}>
            <Field label="Zip" {...register('address.postalCode', { required: VALIDATIONS.REQUIRED })} errors={errors} autoComplete="postal-code" />
          </div>
        </div>
        <div>
          <label htmlFor="country" className={createVariants.label.base}>
            Country
          </label>
          <Controller
            name="address.country"
            control={form.control}
            rules={{ required: VALIDATIONS.REQUIRED }}
            render={({ field }) => (
              <Select
                {...field}
                isClearable={false}
                isSearchable
                options={countries.length ? countries.sort(sortCountries).map(formatCountryOption) : []}
                onChange={async (param) => {
                  const newValue = param as unknown as SelectOption<CountryType>
                  // If new selection is the same as previous, exit early
                  if (form.getValues('address.country.value.id') === newValue.value.id) return
                  field.onChange(newValue)
                  form.setValue('address.state', null)
                  setStatesStatus('loading')
                  try {
                    const states = await getStates(newValue.value.alpha3code)
                    handleGetStatesSuccess(states)
                  } catch (error) {
                    console.error(error)
                    handleGetStatesFail(error as Error)
                  }
                }}
                {...getSelectStyles()}
                onBlur={onBlurWorkaround}
              />
            )}
          />
        </div>
      </FieldGroup>
      <FieldGroup title="Contact Information" description="The contact information for this site.">
        {VALIDATIONS.styleLabel('Calling Code')}
        <Controller
          name="callingCode"
          control={form.control}
          render={({ field }) => (
            <Select
              {...field}
              options={callingCodeOptions}
              value={field.value}
              isClearable={false}
              {...getSelectStyles()}
              onBlur={onBlurWorkaround}
            />
          )}
        />
        <ErrorMessage name="callingCode" errors={form.formState.errors} render={renderErrorMessage} />
        <Field
          label="Phone Number"
          name="phone"
          {...register('phone', { required: VALIDATIONS.REQUIRED, pattern: VALIDATIONS.PHONE_PATTERN })}
          errors={errors}
        />
        <Field label="Extension" {...register('extension')} errors={errors} requiredLabel={false} />
      </FieldGroup>
    </div>
  )
}
