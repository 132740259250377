import { FieldErrors } from 'react-hook-form'
import { ContactResponse, NewContactFormData } from 'types/contacts'

import { useNotification, useWizardContext } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'

import { DuplicateMatchesData } from '..'
import { CreateStepDisplay } from '../CreateStepDisplay'
import { ContactWizardContext } from './CreateContactWizard'
import { NewContactForm } from './NewContactForm'

type Props = {
  createNewEntity: () => Promise<void>
  newEntityStatus: Status
  setNewEntityStatus: React.Dispatch<React.SetStateAction<Status>>
}

export function CreateStep(props: Props) {
  const notification = useNotification()

  const { createNewEntity, newEntityStatus, setNewEntityStatus } = props

  const {
    duplicateMatches: { matches, setMatches, getMatches },
    setSelectedMatch,
    shouldProceed,
    entityType,
    nextStep,
    createForm,
    getSuccessPath,
  } = useWizardContext<ContactWizardContext>()

  const canSubmit = newEntityStatus !== 'loading'

  function handleFoundMatches(matches: DuplicateMatchesData<ContactResponse>) {
    setNewEntityStatus('idle')
    setMatches(matches)
    setSelectedMatch(matches.exact?.[0] || matches.fuzzy?.[0])
    nextStep()
  }

  async function onSubmit(data: NewContactFormData) {
    setNewEntityStatus('loading')

    try {
      const matches = await getMatches(data)

      if (matches === undefined) throw new Error('Error getting matches')

      const hasDuplicates = Boolean(matches?.exact?.length || matches?.fuzzy?.length)

      if (hasDuplicates) {
        handleFoundMatches(matches)
        // Note: exit submit process early if there are any matches
        return
      } else {
        await createNewEntity()
      }
    } catch (e) {
      console.log(e)
      setNewEntityStatus('error')
    }
  }

  function onSubmitError(errors: FieldErrors) {
    notification.api.notify({
      message: 'Some fields are missing or incorrect',
      type: 'warning',
    })
    console.error(errors)
  }

  const hasDuplicates = Boolean(matches?.exact?.length || matches?.fuzzy?.length)

  function resetCreateStep() {
    createForm.reset()
    setMatches(undefined)
    setNewEntityStatus('idle')
  }

  return (
    <CreateStepDisplay
      hasDuplicates={hasDuplicates}
      entityType={entityType}
      canSubmit={canSubmit}
      onSubmit={createForm.handleSubmit(onSubmit, onSubmitError)}
      onReset={resetCreateStep}
      newEntityStatus={newEntityStatus}
      form={createForm}
    >
      <NewContactForm form={createForm} />
    </CreateStepDisplay>
  )
}
