'use client'

import { useSession } from 'next-auth/react'

import { CircleDollarSign, ClipboardText, Dashboard, Megaphone, OfficeBuilding, Users } from '@fsg/icons'

import { CreateAccount } from '@app/components/Create/CreateAccount'

import { CreateContact } from '@app/components/Create/CreateContact'
import { CreateLeadSheet } from '@app/components/Create/CreateLeadSheet'
import { CreateOpportunity } from '@app/components/Create/CreateOpportunity'

import { checkCampaignAuthorization } from '../permissions'
import { CreateCampaignSheet } from '@app/components/Create'

export function useNavRoutes() {
  const { data: sessionData } = useSession()
  const { user: userSession } = sessionData

  type NavRoute = {
    href: string
    label: string
    icon: (props: any) => JSX.Element
    create: (props: any) => JSX.Element
    show: boolean
  }

  const navRoutes: NavRoute[] = [
    { href: '/dashboard', label: 'Dashboard', icon: Dashboard, create: undefined, show: true },
    { href: '/leads', label: 'Leads', icon: ClipboardText, create: CreateLeadSheet, show: true },
    { href: '/opportunities', label: 'Opportunities', icon: CircleDollarSign, create: CreateOpportunity, show: true },
    { href: '/contacts', label: 'Contacts', icon: Users, create: CreateContact, show: true },
    { href: '/accounts', label: 'Accounts', icon: OfficeBuilding, create: CreateAccount, show: true },
    { href: '/campaigns', label: 'Campaigns', icon: Megaphone, create: CreateCampaignSheet, show: checkCampaignAuthorization(userSession) },
  ]

  return navRoutes
}
