import React, { useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { FieldErrors, useForm } from 'react-hook-form'
import { useAppContext, useAppContextSelector } from 'src/app-components/AppContext'
import { usePageContext } from 'src/app-components/PageContextProvider'
import { AccountContextValueType } from 'src/app/accounts/[accountId]/AccountPageContextProvider'

import { ButtonV2, SheetFooter, SheetHeader, SheetTitle, useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { SitesQuery } from '@app/api/EntityQuery'
import { FilterQueryParams } from '@app/api/FilterQueryParams'
import { Form } from '@app/components/Form'
import { ENDPOINTS } from '@app/constants'
import { useSiteService } from '@app/hooks'
import {
  AccountResponse,
  AddressFormData,
  CountryType,
  Option,
  PhoneFormData,
  PhoneResponse,
  SelectOption,
  SiteFormData,
  SiteResponse,
} from '@app/types'
import { formatCallingCodeOption, formatPhoneNumber, getAddressFormData, prepareAccountFormDataFromResponse, routes } from '@app/utils'

import { CreateWizardBaseContextType } from '..'
import { NewSiteForm } from './NewSiteForm'
import { useSiteNameGenerator } from './useSiteNameGenerator'

export type NewSiteFormData = {
  ownerId: Option
  erpId: string
  name: string
  callingCode: SelectOption<CountryType>
  phone: string
  extension?: string
  address: {
    line1: string
    line2: string
    country: SelectOption<CountryType>
    state: SelectOption
    city: string
    postalCode: string
    isPrimary: boolean
  }
  customerSiteId: string
  siteType?: Option
}

export type SiteWizardContext = CreateWizardBaseContextType<SiteResponse, NewSiteFormData>

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  account?: AccountResponse
}

export function CreateSite({ setOpen, account: propsAccount }: Props) {
  const countries = useAppContextSelector((state) => state.countries)
  const { onCreateFunctions } = useAppContext()
  const { request } = useRequestCallback()
  const { data: divisions } = useAppContextSelector((state) => state.divisions)
  const [sites, setSites] = useState<SiteResponse[]>([])
  const account = propsAccount
  const accountData = prepareAccountFormDataFromResponse(account, { countries, divisions })
  const accountAddress = account?.primaryAddress
    ? getAddressFormData(account?.primaryAddress)
    : account.addresses?.length
    ? getAddressFormData(account?.addresses?.[0])
    : (null as AddressFormData)
  const accountPhones = account?.primaryPhone
    ? formatPhoneNumber(account?.primaryPhone, { countries })
    : account?.phones?.length
    ? formatPhoneNumber(account?.phones?.[0], { countries })
    : (null as PhoneFormData)
  const [newSiteStatus, setNewSiteStatus] = React.useState<Status>()

  const pathname = usePathname()
  function findCountry(input: string) {
    return countries.find((country) => country.name === input)
  }
  const callingCodeOptionsMap = new Map(countries.map((country) => [country.alpha3code, formatCallingCodeOption(country)]))

  const callingCodeOptions = Array.from(callingCodeOptionsMap.values())

  const US = findCountry('United States of America')
  const primaryPhone = accountData.phones.find((phone) => phone.isPrimary === true)
  const primaryAddress = accountData.addresses.find((address) => address.isPrimary === true)

  function createPhoneObject(phone: PhoneFormData) {
    return formatPhoneNumber(
      {
        label: phone.label,
        number: `+${phone.callingCode.value.telephoneCode}${phone.number}`,
        id: phone.id,
        isPrimary: phone.isPrimary,
        extension: phone.extension,
      },
      { countries },
    )
  }

  async function handleSiteNameCopy(data: NewSiteFormData) {
    const name = data.name
    const copyName = await siteGenerator.generateCopy(name)
    const replaceName = { name: copyName }
    return { ...data, ...replaceName }
  }

  function getDefaultPhoneNumber() {
    if (accountPhones) {
      return createPhoneObject(accountPhones)
    }

    if (accountData.phones.length > 0) {
      const phone = primaryPhone || accountData.phones[0]
      return createPhoneObject(phone)
    }

    return {
      label: 'Phone',
      callingCode: callingCodeOptionsMap.get('US'),
      number: '',
      isPrimary: true,
      extension: '',
    }
  }

  function createAddressObject(address: AddressFormData) {
    return {
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: {
        label: address.country.label,
        value: findCountry(address.country.value),
      },
      isPrimary: true,
    }
  }

  function getDefaultAddress() {
    if (accountAddress) {
      return createAddressObject(accountAddress)
    }

    if (accountData.addresses.length > 0) {
      const address = primaryAddress || accountData.addresses[0]
      return createAddressObject(address)
    }

    return {
      line1: '',
      line2: '',
      city: '',
      state: null,
      postalCode: '',
      country: {
        label: US.alpha3code,
        value: US,
      },
      isPrimary: true,
    }
  }

  const defaultPhoneNumber = getDefaultPhoneNumber()

  const createForm = useForm<NewSiteFormData>({
    defaultValues: {
      ownerId: account ? { label: account.name, value: account.id } : undefined,
      address: getDefaultAddress(),
      phone: defaultPhoneNumber.number,
      extension: defaultPhoneNumber.extension || '',
      callingCode: defaultPhoneNumber.callingCode,
      customerSiteId: '',
    },
  })

  const notification = useNotification()
  const [error, setError] = useState<Error | null>(null)

  const service = useSiteService()
  const query = new SitesQuery()
  const fq = new FilterQueryParams()
  const router = useRouter()
  const entityType = 'Site'

  const siteGenerator = useSiteNameGenerator({
    account: account,
    customerSiteId: createForm.watch('customerSiteId'),
    siteName: createForm.watch('name'),
  })

  const closeSheet = () => setOpen(false)
  function handleSuccessfulCreate(response: SiteResponse) {
    createForm.reset()
    closeSheet()
    router.push(routes.site.general(response.id))
    setNewSiteStatus('success')
  }

  function handleFailedCreate(error: any) {
    setError(error as Error)
    setNewSiteStatus('error')
    notification.api.notify({
      message: `Error Creating ${entityType} - ${error.message}`,
      type: 'warning',
    })
  }

  async function onSubmit(data: NewSiteFormData) {
    setNewSiteStatus('loading')

    try {
      const processedData = await handleSiteNameCopy(data)
      const response = await service.create(processedData)
      if (onCreateFunctions.site?.useDefault === false && pathname.includes(onCreateFunctions.site?.data?.route)) {
        onCreateFunctions?.site?.function(response)
      } else {
        handleSuccessfulCreate(response)
      }
    } catch (error: any) {
      handleFailedCreate(error)
    }
  }

  function onSubmitError(errors: FieldErrors) {
    notification.api.notify({
      message: 'Some fields are missing or incorrect',
      type: 'warning',
    })
    console.error(errors)
  }

  return (
    <Form form={createForm} isEditing onSubmit={createForm.handleSubmit(onSubmit, onSubmitError)}>
      <SheetHeader className="pb-4">
        <SheetTitle className="text-4xl">Create Site</SheetTitle>
      </SheetHeader>
      <NewSiteForm form={createForm} nameGenerator={siteGenerator} />
      <SheetFooter className="flex justify-end gap-sm py-12">
        <ButtonV2
          type="button"
          size="lg"
          variant="secondary"
          onClick={() => {
            createForm.reset()
          }}
        >
          Reset
        </ButtonV2>
        <ButtonV2 size="lg" variant="primary" type="submit" className="font-bold" loading={newSiteStatus === 'loading'}>
          Save as New Site
        </ButtonV2>
      </SheetFooter>
    </Form>
  )
}
